import { startOfDay, subDays, isToday, isYesterday, isThisWeek } from 'date-fns';
import {
  fetchAlertById,
  fetchLocationEnterEvents,
  fetchVideosForTimeRange,
  fetchLatestLocationEvent,
  fetchVideoByFileName,
} from './cosmosQueries';

const MIN_TIME_BETWEEN_EVENTS = 60;

export const getShiftOfflineTime = async (shift) => {
  const lastSeen = shift.lastSeen ? new Date(shift.lastSeen) : null;
  const currentTime = new Date();

  // Fetch the latest location event
  const latestLocationEvent = await fetchLatestLocationEvent(shift.deviceId, currentTime.toISOString());
  // if latestLocationEvent is null, then no location event has been seen for the device
  const latestLocationTime = latestLocationEvent ? new Date(latestLocationEvent.enter_time) : null;
  // const latestLocationTime = null;

  // Determine the most recent time between lastSeen and latestLocationTime
  // if latestLocationTime is null, we want to use lastSeen
  // if lastSeen is null, we want to use latestLocationTime
  // if latestLocationTime is greater than lastSeen, we want to use latestLocationTime
  // if lastSeen is greater than latestLocationTime, we want to use lastSeen
  const mostRecentTime =
    latestLocationTime && (!lastSeen || latestLocationTime > lastSeen) ? latestLocationTime : lastSeen;

  if (mostRecentTime) {
    const timeDifference = currentTime.getTime() - mostRecentTime.getTime();
    const isOffline = timeDifference > 30 * 60 * 1000; // 30 minutes in milliseconds
    return { isOffline, offlineSince: mostRecentTime };
  }

  return { isOffline: false, offlineSince: null };
};

export const groupShiftsByDateRange = (shifts) => {
  const today = startOfDay(new Date());
  const yesterday = subDays(today, 1);
  return shifts.reduce(
    (acc, shift) => {
      const shiftDate = new Date(shift.startTime);
      if (isToday(shiftDate)) {
        acc.today.push(shift);
      } else if (isYesterday(shiftDate)) {
        acc.yesterday.push(shift);
      } else if (isThisWeek(shiftDate)) {
        acc.lastWeek.push(shift);
      } else {
        acc.older.push(shift);
      }
      return acc;
    },
    { today: [], yesterday: [], lastWeek: [], older: [] }
  );
};

export const filterShiftEvents = async (shift) => {
  const timings = {
    total: 0,
    fetchAlertById: 0,
    fetchVideos: 0,
    fetchLocationEnterEvents: 0,
    fetchLatestLocationEvent: 0,
    processing: 0,
  };

  const startTime = performance.now();

  const filteredEvents = [];
  const events = shift.events;
  let calculatedOfflineTime = 0;
  let lastStartEvent = events[0];
  let lastStartEventIndex = 0;
  // let latestLocationFlag = false;

  // Cache for alert data
  const alertCache = new Map();

  // Batch fetch alerts
  const alertIds = events.filter((event) => event.type === 'escalation').map((event) => event.alertId);
  const alertPromises = alertIds.map(async (alertId) => {
    if (!alertCache.has(alertId)) {
      const fetchStart = performance.now();
      try {
        const data = await fetchAlertById(alertId);
        alertCache.set(alertId, data);
        timings.fetchAlertById += performance.now() - fetchStart;
      } catch (err) {
        console.error('Failed to fetch alert data:', err);
        alertCache.set(alertId, null);
      }
    }
  });

  // Wait for all alert fetches to complete
  await Promise.all(alertPromises);

  for (let i = 0; i < events.length; i++) {
    const currentEvent = events[i];
    const nextEvent = events[i + 1];

    if (currentEvent.type === 'escalation') {
      const alertData = alertCache.get(currentEvent.alertId);
      if (!alertData || (alertData.hasOwnProperty('IsDeleted') && alertData.IsDeleted)) {
        continue;
      }
    }

    // Check for consecutive start-end or end-start events
    if (nextEvent) {
      const timeDiff = (new Date(nextEvent.timestamp) - new Date(currentEvent.timestamp)) / 1000;
      if (
        (currentEvent.type === 'start' &&
          nextEvent.type === 'end' &&
          timeDiff <= MIN_TIME_BETWEEN_EVENTS &&
          i !== 0 &&
          i + 1 !== events.length - 1) ||
        (currentEvent.type === 'end' && nextEvent.type === 'start' && timeDiff <= MIN_TIME_BETWEEN_EVENTS)
      ) {
        i++;
        continue;
      } else if (currentEvent.type === 'start') {
        lastStartEvent = currentEvent;
        lastStartEventIndex = i;
      }
    }

    if (currentEvent.type === 'end') {
      const videosFetchStart = performance.now();
      const lastStartEventTimestampMinus15Sec = new Date(new Date(lastStartEvent.timestamp).getTime() - 15000);
      let videos = await fetchVideosForTimeRange(
        shift.deviceId,
        lastStartEventTimestampMinus15Sec.toISOString(),
        currentEvent.timestamp
      );
      videos = videos.map((video) => {
        if (new Date(video.TimeRecorded) < new Date(lastStartEvent.timestamp)) {
          video.TimeRecorded = new Date(lastStartEvent.timestamp).toISOString();
        }
        return video;
      });
      // console.log('videos', videos);
      videos = filterOverlappingVideos(videos);
      timings.fetchVideos += performance.now() - videosFetchStart;

      const locationFetchStart = performance.now();
      const locationEnterEvents = await fetchLocationEnterEvents(
        shift.deviceId,
        lastStartEvent.timestamp,
        currentEvent.timestamp
      );
      if (shift.deviceId === 'cpg5') {
        console.log('locationEnterEvents', locationEnterEvents);
      }
      timings.fetchLocationEnterEvents += performance.now() - locationFetchStart;

      for (const video of videos) {
        const fileNameParts = video.FileName.split('-');
        if (fileNameParts[1] !== 'escalation') {
          filteredEvents.push(createRecordingVideoEvent(video));
        }
      }
      for (let i = 0; i < locationEnterEvents.length; i++) {
        let currentEvent = locationEnterEvents[i];
        let nextEvent = locationEnterEvents[i + 1];

        while (
          nextEvent &&
          currentEvent.geofence_id === nextEvent.geofence_id &&
          (new Date(nextEvent.enter_time) - new Date(currentEvent.exit_time)) / 1000 <= 300
        ) {
          currentEvent = {
            ...currentEvent,
            exit_time: nextEvent.exit_time,
          };
          i++;
          nextEvent = locationEnterEvents[i + 1];
        }

        if (currentEvent.exit_time === null) {
          currentEvent = {
            ...currentEvent,
            exit_time: nextEvent ? nextEvent.exit_time : null,
          };
        }

        filteredEvents.push(createLocationEnterEvent(currentEvent));
      }
      if (shift.deviceId === 'cpg5') {
        console.log('filteredEvents', filteredEvents);
      }
      if (locationEnterEvents.length === 0 && lastStartEventIndex === 0) {
        const latestLocationFetchStart = performance.now();
        const latestLocationEvent = await fetchLatestLocationEvent(shift.deviceId, currentEvent.timestamp);
        console.log('latestLocationEvent', latestLocationEvent);
        timings.fetchLatestLocationEvent += performance.now() - latestLocationFetchStart;

        if (latestLocationEvent) {
          const locationEnterEvent = createLocationEnterEvent(latestLocationEvent);
          // Set the timestamp to lastStartEvent.timestamp
          locationEnterEvent.timestamp = lastStartEvent.timestamp;
          if (latestLocationEvent.exit_time && latestLocationEvent.exit_time > lastStartEvent.timestamp) {
            filteredEvents.push(locationEnterEvent);
          } else if (!latestLocationEvent.exit_time) {
            filteredEvents.push(locationEnterEvent);
          }
          // latestLocationFlag = true;
        }
      }
    }
    filteredEvents.push(currentEvent);

    // Calculate offline time
    if (currentEvent.type === 'end' && nextEvent && nextEvent.type === 'start') {
      const offlineDuration = (new Date(nextEvent.timestamp) - new Date(currentEvent.timestamp)) / 1000;
      calculatedOfflineTime += offlineDuration;
    }
  }

  const processingStart = performance.now();
  filteredEvents.sort((a, b) => {
    const aTime = Math.floor(new Date(a.timestamp).getTime() / 1000);
    const bTime = Math.floor(new Date(b.timestamp).getTime() / 1000);

    if (aTime === bTime) {
      // If timestamps are the same to the second, sort based on event type
      if (a.type === 'start') return -1;
      if (b.type === 'start') return 1;
      if (a.type === 'end') return 1;
      if (b.type === 'end') return -1;
      if (a.type === 'recordingVideo' && b.type !== 'end') return 1;
      if (b.type === 'recordingVideo' && a.type !== 'end') return -1;
    }
    return aTime - bTime;
  });

  // Update the shift

  const updatedShift = { ...shift, events: filteredEvents };
  // if (shift.id === "9b4b4172-4745-4527-99fe-219d10f759d1") {
  //   console.log('updatedShift from events filter', updatedShift);
  // }

  if (filteredEvents.length > 0) {
    if (filteredEvents[0].type === 'start') {
      updatedShift.startTime = filteredEvents[0].timestamp;
    } else {
      updatedShift.startTime = filteredEvents[1].timestamp;
    }
    if (!shift.inProgress && filteredEvents.length > 1) {
      updatedShift.endTime = filteredEvents[filteredEvents.length - 1].timestamp;
      updatedShift.duration = Math.round((new Date(updatedShift.endTime) - new Date(updatedShift.startTime)) / 1000);

      // Calculate online time
      updatedShift.calculatedOfflineTime = calculatedOfflineTime;
      updatedShift.calculatedOnlineTime = updatedShift.duration - calculatedOfflineTime;
    }
  }

  timings.processing += performance.now() - processingStart;
  timings.total = performance.now() - startTime;

  console.log('Performance timings (ms):', timings);

  return updatedShift;
};

export const getDaysDifference = (start, end) => {
  const startDate = new Date(start).setHours(0, 0, 0, 0);
  const endDate = new Date(end).setHours(0, 0, 0, 0);
  return Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
};

const createRecordingVideoEvent = (video) => {
  return {
    type: 'recordingVideo',
    timestamp: video.TimeRecorded,
    duration: video.Duration,
    fileName: video.FileName,
    blobPath: video.BlobPath,
  };
};

const createLocationEnterEvent = (geofenceEvent) => {
  let locationEventObject = {
    type: 'locationEnter',
    geofenceId: geofenceEvent.geofence_id,
    timestamp: geofenceEvent.enter_time,
    deviceId: geofenceEvent.device,
    locationHash: geofenceEvent.enter_location,
    exitTime: geofenceEvent.exit_time,
  };
  if (geofenceEvent.last_seen_time) {
    locationEventObject.lastSeenTime = geofenceEvent.last_seen_time;
  }
  return locationEventObject;
};

const filterOverlappingVideos = (videos) => {
  return videos.reduce((acc, current) => {
    const overlap = acc.find((video) => {
      const currentStart = new Date(current.TimeRecorded).getTime();
      const currentEnd = currentStart + parseInt(current.Duration);
      const videoStart = new Date(video.TimeRecorded).getTime();
      const videoEnd = videoStart + parseInt(video.Duration);
      return currentStart < videoEnd && currentEnd > videoStart;
    });
    if (!overlap) {
      acc.push(current);
    }
    return acc;
  }, []);
};
