// GeofenceControls.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import '../styles/GeofenceControls.css';

const GeofenceControls = ({ geofence, onSave, onCancel, onChange, isPreview, onRadiusChange }) => {
  const [nickname, setNickname] = useState(geofence.nickname || '');
  const [radius, setRadius] = useState(geofence.radius || 150);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setNickname(geofence.nickname || '');
    setRadius(geofence.radius || 150);
    setIsEdited(false);
  }, [geofence.id]);

  const handleNicknameChange = (e) => {
    const newNickname = e.target.value;
    setNickname(newNickname);
    onChange({ ...geofence, nickname: newNickname });
    setIsEdited(true);
  };

  const handleRadiusChange = (e) => {
    const newRadius = parseInt(e.target.value, 10);
    setRadius(newRadius);
    onRadiusChange(newRadius);
    onChange({ ...geofence, radius: newRadius });
    setIsEdited(true);
  };

  const handleSave = () => {
    onSave({ ...geofence, nickname, radius });
  };

  const metersToFeetOrMiles = (meters) => {
    const feet = meters * 3.28084;
    if (feet > 528) {
      // 0.1 miles in feet
      return `${(feet / 5280).toFixed(1)} mi`;
    }
    return `${Math.round(feet)} ft`;
  };

  return (
    <div className="geofence-controls">
      <div className="geofence-header">
        <span className="geofence-address">{isPreview ? geofence.full_address : geofence.id}</span>
        <button className="close-button" onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <div className="geofence-control">
        <label htmlFor="nickname">Nickname</label>
        <input
          type="text"
          id="nickname"
          value={nickname}
          onChange={handleNicknameChange}
          placeholder="Enter nickname"
        />
      </div>
      <div className="geofence-control">
        <label htmlFor="radius">
          Radius <span className="radius-value">{metersToFeetOrMiles(radius)}</span>
        </label>
        <input
          type="range"
          id="radius"
          value={radius}
          onChange={handleRadiusChange}
          min="150"
          max="500"
          step="1"
          style={{ '--progress': `${(radius - 150) / 3.5}%` }}
        />
      </div>
      <div className="geofence-actions">
        {isEdited && (
          <button onClick={onCancel} className="cancel-button">
            Cancel
          </button>
        )}
        <button onClick={handleSave} className="save-button" disabled={!isEdited}>
          <FontAwesomeIcon icon={faCheck} /> Save
        </button>
      </div>
    </div>
  );
};

export default GeofenceControls;
