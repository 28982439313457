import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTrashAlt as fasTrashAlt,
  faThumbsUp as fasThumbsUp,
  faThumbsDown as fasThumbsDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTrashAlt as farTrashAlt,
  faThumbsUp as farThumbsUp,
  faThumbsDown as farThumbsDown,
} from '@fortawesome/free-regular-svg-icons';
import '../styles/Card.css';
import { stringToColor } from '../utils/utilsUI';
import { format, isToday, isYesterday, isSameYear } from 'date-fns';
import { updateAlertLabels } from '../utils/cosmosQueries';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { getCategories } from '../utils/categoryUtils';
import { updateAlertOnThumbsUp, updateAlertOnThumbsDown } from '../utils/modifyAlerts';

library.add(fasTrashAlt, farTrashAlt, fasThumbsUp, farThumbsUp, fasThumbsDown, farThumbsDown, faTimes);

const Card = ({ userId, initialCard, icon, onDeviceSelect, onCardClick }) => {
  const [card, setCard] = useState(initialCard);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showCategoryTooltip, setShowCategoryTooltip] = useState(false);
  const [showCategorySelector, setShowCategorySelector] = useState(false);
  const [categories, setCategories] = useState([]);

  const isOverwatch = userId === PLIX_OVERWATCH_ACCOUNT;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        // Optionally set categories to an empty array or handle the error as needed
        setCategories([]);
      }
    };

    fetchCategories();
  }, []);

  const handleClick = () => {
    if (onCardClick) {
      console.log('Card clicked:', card);
      onCardClick(card);
    }
  };
  const parseCategories = (categoriesString) => {
    if (typeof categoriesString !== 'string') return [];
    return categoriesString
      .split(',')
      .map((cat) => cat.trim())
      .filter((cat) => cat !== '' && cat !== 'Unlabeled');
  };

  const getCategoryLabel = () => {
    if (card.CategorizationResult) {
      const trueCategories = parseCategories(card.CategorizationResult['true']);
      if (trueCategories.length > 0) return trueCategories[0];

      if (card.DeployedModelUsed) {
        const categories = parseCategories(card.CategorizationResult[card.DeployedModelUsed]);
        if (categories.length > 0) return categories[0];
      }
    }
    return null;
  };

  const categoryLabel = getCategoryLabel();
  const categoryColor = categoryLabel ? stringToColor(categoryLabel) : null;

  const renderCategoryTooltip = () => {
    if (!card.CategorizationResult) return null;

    return (
      <div className="category-tooltip">
        {Object.entries(card.CategorizationResult).map(([model, categoriesString]) => {
          if (typeof categoriesString !== 'string') return null;

          const categories = parseCategories(categoriesString);
          if (categories.length === 0) return null;

          return (
            <div key={model} className={`tooltip-row ${model === card.DeployedModelUsed ? 'highlighted' : ''}`}>
              <span className="model-name">{model}</span>
              <div className="category-pills">
                {categories.map((category, index) => (
                  <span key={index} className="category-pill" style={{ backgroundColor: stringToColor(category) }}>
                    {category}
                  </span>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const toggleCategorySelector = (e) => {
    if (e) e.stopPropagation();
    setShowCategorySelector(!showCategorySelector);
  };

  const handleCategoryClick = async (categoryName) => {
    console.log('categoryName: ', categoryName);
    const currentTrueCategories = parseCategories(card.CategorizationResult?.['true'] || '');
    let newTrueCategories;

    if (currentTrueCategories.includes(categoryName)) {
      newTrueCategories = currentTrueCategories.filter((cat) => cat !== categoryName);
    } else {
      newTrueCategories = [...currentTrueCategories, categoryName];
    }

    const newCategorizationResult = {
      ...card.CategorizationResult,
      true: newTrueCategories.join(','),
    };

    try {
      const updatedCard = await updateAlertLabels(card.id, card.DeviceId, {
        CategorizationResult: newCategorizationResult,
      });
      setCard((prevCard) => ({
        ...prevCard,
        CategorizationResult: newCategorizationResult,
      }));
    } catch (error) {
      console.error('Error updating categories:', error);
    }
  };

  const renderCategorySelector = () => {
    const trueCategories = parseCategories(card.CategorizationResult?.['true'] || '');
    console.log('categories: ', categories);

    return (
      <div className="category-selector">
        <button className="close-button" onClick={toggleCategorySelector}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="category-list">
          {categories.map((category) => (
            <span
              key={category.name}
              className={`category-pill ${trueCategories.includes(category.name) ? 'active' : ''}`}
              onClick={(e) => {
                e.stopPropagation();
                handleCategoryClick(category.name);
              }}
              style={{ backgroundColor: stringToColor(category.name) }}
            >
              {category.name}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const renderClassificationTooltip = () => {
    const classificationResult = card.ClassificationResult || {};
    const highlightedModel = card.DeployedModelUsed || '';

    console.log('highlightedModel: ', highlightedModel, ' classificationResult: ', classificationResult);

    return (
      <div className="classification-tooltip">
        {Object.entries(classificationResult).map(([model, classification]) => (
          <div key={model} className={`tooltip-row ${model === highlightedModel ? 'highlighted' : ''}`}>
            <span className="model-name">{model}</span>
            {classification === 'Escalation' && <FontAwesomeIcon icon={fasThumbsUp} className="classification-icon" />}
            {classification === 'Not Escalation' && (
              <FontAwesomeIcon icon={fasThumbsDown} className="classification-icon" />
            )}
          </div>
        ))}
      </div>
    );
  };

  const handleDeleteToggle = async (e) => {
    e.stopPropagation();
    try {
      const updatedCard = await updateAlertLabels(card.id, card.DeviceId, { IsDeleted: !card.IsDeleted });
      setCard((prevCard) => ({ ...prevCard, IsDeleted: updatedCard.IsDeleted }));
      console.log('Card deleted status updated:', updatedCard);
    } catch (error) {
      console.error('Error updating card delete status:', error);
    }
  };

  const handleThumbsClick = async (isUp) => {
    try {
      // Update Cosmos
      const updatedCard = isUp
        ? await updateAlertOnThumbsUp(card.id, card.DeviceId)
        : await updateAlertOnThumbsDown(card.id, card.DeviceId);

      // Update local state
      setCard((prevCard) => ({
        ...prevCard,
        ClassificationResult: updatedCard.ClassificationResult,
      }));

      console.log('Card classification updated:', updatedCard);
    } catch (error) {
      console.error('Error updating card classification:', error);
    }
  };

  const handleDeviceClick = (e) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the card
    if (onDeviceSelect) {
      onDeviceSelect(card.DeviceId);
    }
  };

  const backgroundColor = stringToColor(card.AssignedTo || card.DeviceId);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    if (isToday(date)) {
      return 'Today';
    } else if (isYesterday(date)) {
      return 'Yesterday';
    } else if (isSameYear(date, new Date())) {
      return format(date, 'M/d');
    } else {
      return format(date, 'M/d/yyyy');
    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return format(date, 'h:mm');
  };

  const formatAmPm = (timestamp) => {
    const date = new Date(timestamp);
    return format(date, 'a');
  };

  const cardClassName = `card ${card.Thumbnail ? 'with-thumbnail' : ''} 
  ${!card.IsCustomerVisible ? 'not-customer-visible' : ''} 
  ${card.IsDeleted ? 'deleted' : ''}`;

  const currentClassification = card.ClassificationResult?.['true'] || null;
  const deleteButtonClassName = card.IsDeleted ? 'delete-button-active' : '';

  return (
    <div className={cardClassName} onClick={handleClick}>
      {card.Thumbnail && <img src={card.Thumbnail} alt="Thumbnail" className="card-thumbnail" />}
      <div className="card-content">
        <div className="card-body">
          <div className="card-top">
            <div className="time-info">
              <div className="date">{formatDate(card.Timestamp)}</div>
              <div className="time">{formatTime(card.Timestamp)}</div>
              <div className="am-pm">{formatAmPm(card.Timestamp)}</div>
            </div>
          </div>
          <div className="card-details">
            <p
              className="alert-deviceId"
              style={{ backgroundColor, padding: '5px 10px', borderRadius: '5px', color: '#333', cursor: 'pointer' }}
              onClick={handleDeviceClick}
            >
              {card.AssignedTo || card.DeviceId}
            </p>
            <p className="card-label">
              {categoryLabel && ['Theft Attempt', 'Trespassing', 'BOLO Alert'].includes(categoryLabel)
                ? categoryLabel
                : card.Label}
            </p>
            <p className="card-description">{card.Label === 'End of Contact' ? '' : card.Description}</p>
          </div>
        </div>
        {
          <div className="card-footer">
            {categoryLabel && isOverwatch && (
              <div
                className="category-label"
                style={{ backgroundColor: categoryColor }}
                onMouseEnter={() => (isOverwatch ? setShowCategoryTooltip(true) : setShowCategoryTooltip(false))}
                onMouseLeave={() => (isOverwatch ? setShowCategoryTooltip(false) : setShowCategoryTooltip(false))}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCategorySelector();
                }}
              >
                {categoryLabel}
                {showCategorySelector && renderCategorySelector()}
                {showCategoryTooltip && renderCategoryTooltip()}
              </div>
            )}
            {isOverwatch && (
              <div className="card-actions">
                <div
                  className="thumbs-container"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  <button onClick={() => handleThumbsClick(true)} className="action-button">
                    <FontAwesomeIcon icon={currentClassification === 'Escalation' ? fasThumbsUp : farThumbsUp} />
                  </button>
                  <button onClick={() => handleThumbsClick(false)} className="action-button">
                    <FontAwesomeIcon
                      icon={currentClassification === 'Not Escalation' ? fasThumbsDown : farThumbsDown}
                    />
                  </button>
                  {showTooltip && renderClassificationTooltip()}
                </div>
                <button onClick={handleDeleteToggle} className={`action-button delete-button ${deleteButtonClassName}`}>
                  <FontAwesomeIcon icon={card.IsDeleted ? fasTrashAlt : farTrashAlt} />
                </button>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default Card;
