import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import L from 'leaflet';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/MapView.css';
import 'leaflet/dist/leaflet.css';
import { Circle } from 'react-leaflet';

const PlayIcon = () => (
  <svg width="15" height="15" viewBox="0 0 24 24" fill="currentColor">
    <path d="M8 5v14l11-7z" />
  </svg>
);

const PauseIcon = () => (
  <svg width="15" height="15" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 6h12v12H6z" />
  </svg>
);

const AnalogClock = ({ time }) => {
  const hours = time.getHours() % 12;
  const minutes = time.getMinutes();

  const hourAngle = (hours + minutes / 60) * 30;
  const minuteAngle = minutes * 6;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" style={{ marginRight: '5px' }}>
      <circle cx="8" cy="8" r="7" fill="none" stroke="currentColor" strokeWidth="1" />
      <line
        x1="8"
        y1="8"
        x2="8"
        y2="4"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        transform={`rotate(${hourAngle}, 8, 8)`}
      />
      <line
        x1="8"
        y1="8"
        x2="8"
        y2="3"
        stroke="currentColor"
        strokeWidth="0.5"
        strokeLinecap="round"
        transform={`rotate(${minuteAngle}, 8, 8)`}
      />
    </svg>
  );
};

const MapView = ({ locations = [], size = 'large', event, events, geofences, focusLocation }) => {
  const locationsLoaded = useRef(false);
  const [map, setMap] = useState(null);
  const [currentTime, setCurrentTime] = useState(event ? new Date(event.timestamp) : null);
  const [isPlaying, setIsPlaying] = useState(false);
  const markerRef = useRef(null);
  const [markerTime, setMarkerTime] = useState(null);
  const [eventMarkerTime, setEventMarkerTime] = useState(null);
  const [eventMarkerPosition, setEventMarkerPosition] = useState(null);
  const [showFullRoute, setShowFullRoute] = useState(true);
  const polylineRef = useRef(null);
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const [isInitiallyZoomedIn, setIsInitiallyZoomedIn] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const animationRef = useRef(null);
  const targetTimeRef = useRef(null);
  const routeLineRef = useRef(null);
  const routeDotsRef = useRef(null);
  const [showRoute, setShowRoute] = useState(true);
  const [showDots, setShowDots] = useState(false);
  const animationFrameRef = useRef(null);
  const [isRouteVisible, setIsRouteVisible] = useState(false);
  const isPlayingRef = useRef(false);
  const [geofenceCircle, setGeofenceCircle] = useState(null);
  const geofenceCircleRef = useRef(null);

  useEffect(() => {
    if (locations.length > 0 && !locationsLoaded.current) {
      locationsLoaded.current = true;
    }
  }, [locations]);

  useEffect(() => {
    // Check if we should auto-play
    if (locationsLoaded.current && event && event.type === 'start' && events.indexOf(event) === 0) {
      handlePlayPause(true);
    } else {
      handlePlayPause(false);
    }
  }, [event, events, locationsLoaded.current]);

  useEffect(() => {
    if (event && event.type === 'locationEnter' && event.geofenceId && geofences) {
      const geofence = geofences.find((g) => g.id === event.geofenceId);
      if (geofence) {
        setGeofenceCircle({
          center: [geofence.latitude, geofence.longitude],
          radius: geofence.radius,
        });
      } else {
        setGeofenceCircle(null);
      }
    } else {
      setGeofenceCircle(null);
    }
  }, [event, geofences]);

  useEffect(() => {
    if (map && geofenceCircle) {
      if (geofenceCircleRef.current) {
        geofenceCircleRef.current.remove();
      }
      geofenceCircleRef.current = L.circle(geofenceCircle.center, {
        radius: geofenceCircle.radius,
        color: 'red',
        fillOpacity: 0.1,
        weight: 2,
      }).addTo(map);
    } else if (map && geofenceCircleRef.current) {
      geofenceCircleRef.current.remove();
      geofenceCircleRef.current = null;
    }
  }, [map, geofenceCircle]);

  useEffect(() => {
    if (map && focusLocation) {
      const { latitude, longitude } = focusLocation;

      // Fly to the new location
      map.flyTo([latitude, longitude], map.getZoom(), {
        duration: 1.5, // Animation duration in seconds
        easeLinearity: 0.25,
      });

      // Update or create the marker
      if (markerRef.current) {
        markerRef.current.setLatLng([latitude, longitude]);
      } else {
        markerRef.current = L.marker([latitude, longitude], {
          icon: createCustomIcon(),
        }).addTo(map);
      }
    }
  }, [map, focusLocation]);

  useEffect(() => {
    if (map && event) {
      const eventTime = new Date(event.timestamp);
      const closestLocation = locations.reduce((prev, curr) => {
        const prevDiff = Math.abs(new Date(prev.time) - eventTime);
        const currDiff = Math.abs(new Date(curr.time) - eventTime);
        return currDiff < prevDiff ? curr : prev;
      });

      if (closestLocation) {
        const { latitude, longitude } = closestLocation;

        // Fly to the new location
        map.flyTo([latitude, longitude], map.getZoom(), {
          duration: 0.5, // Animation duration in seconds
          easeLinearity: 0.2,
        });

        // Update or create the marker
        if (markerRef.current) {
          markerRef.current.setLatLng([latitude, longitude]);
        } else {
          markerRef.current = L.marker([latitude, longitude], {
            icon: createCustomIcon(),
          }).addTo(map);
        }
      }
    }
  }, [map, event, locations]);

  const createCustomIcon = useCallback(() => {
    return L.divIcon({
      className: 'custom-icon',
      html: `<svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
               <circle cx="25" cy="25" r="20" fill="red" opacity="0.4"/>
             </svg>`,
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      popupAnchor: [0, -25],
    });
  }, []);

  const updateRouteDisplay = useCallback(() => {
    if (!map || !routeLineRef.current || !routeDotsRef.current) {
      return;
    }
    const zoom = map.getZoom();
    const maxZoom = map.getMaxZoom();
    const bounds = map.getBounds();
    const visibleLocations = locations.filter((loc) => bounds.contains([loc.latitude, loc.longitude]));

    const shouldShowDots = zoom >= maxZoom - 1 && visibleLocations.length >= 10;

    if (isRouteVisible) {
      if (shouldShowDots) {
        routeLineRef.current.setStyle({ opacity: 0 });
        routeDotsRef.current.eachLayer((layer) => {
          layer.setStyle({ fillOpacity: 0.6 });
        });
      } else {
        routeLineRef.current.setStyle({ opacity: 1 });
        routeDotsRef.current.eachLayer((layer) => {
          layer.setStyle({ fillOpacity: 0 });
        });
      }
    } else {
      routeLineRef.current.setStyle({ opacity: 0 });
      routeDotsRef.current.eachLayer((layer) => {
        layer.setStyle({ fillOpacity: 0 });
      });
    }

    setShowDots(shouldShowDots);
  }, [map, locations, isRouteVisible]);

  // Add this effect to actually change the map layers when showDots changes
  useEffect(() => {
    if (map && routeLineRef.current && routeDotsRef.current) {
      if (showDots) {
        map.removeLayer(routeLineRef.current);
        routeDotsRef.current.addTo(map);
      } else {
        map.removeLayer(routeDotsRef.current);
        routeLineRef.current.addTo(map);
      }
    }
  }, [map, showDots]);

  useEffect(() => {
    if (map) {
      map.on('zoomend moveend', updateRouteDisplay);
      updateRouteDisplay(); // Call this to update route visibility when isRouteVisible changes
      return () => {
        map.off('zoomend moveend', updateRouteDisplay);
      };
    }
  }, [map, updateRouteDisplay, isRouteVisible]);

  const createRoute = useCallback(() => {
    if (map && locations.length > 0) {
      // Remove existing routes if any
      if (routeLineRef.current) {
        map.removeLayer(routeLineRef.current);
      }
      if (routeDotsRef.current) {
        map.removeLayer(routeDotsRef.current);
      }

      // Create opaque line
      const routeLine = L.polyline(
        locations.map((loc) => [loc.latitude, loc.longitude]),
        {
          color: '#e74d3c',
          weight: 3,
          opacity: 1,
        }
      );

      // Create small red dots
      const routeDots = L.layerGroup();
      locations.forEach((location) => {
        L.circleMarker([location.latitude, location.longitude], {
          radius: 5,
          fillColor: '#e74d3c',
          fillOpacity: 0,
          stroke: false,
        }).addTo(routeDots);
      });

      routeLineRef.current = routeLine;
      routeDotsRef.current = routeDots;

      // Add both layers to the map, but set their opacity to 0 initially
      routeLine.addTo(map).setStyle({ opacity: 0 });
      routeDots.addTo(map).eachLayer((layer) => layer.setStyle({ fillOpacity: 0 }));

      // Then update the display based on zoom level
      updateRouteDisplay();
    }
  }, [map, locations, updateRouteDisplay]);

  useEffect(() => {
    if (event && locations.length > 0) {
      const startTime = new Date(events[0].timestamp);
      const endTime = new Date(
        Math.max(
          new Date(events[events.length - 1].timestamp).getTime(),
          new Date(locations[locations.length - 1].time).getTime()
        )
      );
      const totalDuration = endTime - startTime;
      const eventTime = new Date(event.timestamp);

      // Calculate the position as a percentage
      const position = ((eventTime - startTime) / totalDuration) * 100;
      setEventMarkerPosition(Math.max(0, Math.min(100, position))); // Ensure it's between 0 and 100
      console.log(
        'startTime: ',
        startTime,
        ' totalDuration: ',
        totalDuration,
        ' position: ',
        position,
        ' eventTime: ',
        eventTime,
        ' event: ',
        event,
        ' locations: ',
        locations
      );
    } else {
      setEventMarkerPosition(null);
    }
  }, [event, locations]);

  const displayMap = useCallback(
    (node) => {
      if (node !== null && map === null && locations.length > 0) {
        const newMap = L.map(node, {
          zoomControl: true,
          dragging: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          attributionControl: false,
        });

        L.tileLayer(
          `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`,
          {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          }
        ).addTo(newMap);

        markerRef.current = L.marker([locations[0].latitude, locations[0].longitude], {
          icon: createCustomIcon(),
        }).addTo(newMap);

        const bounds = L.latLngBounds(locations.map((loc) => [loc.latitude, loc.longitude]));

        newMap.fitBounds(bounds, {
          padding: [50, 50],
          maxZoom: 18,
        });

        // Determine if the initial view should be zoomed in
        const boundsZoom = newMap.getBoundsZoom(bounds);
        const maxZoom = newMap.getMaxZoom();
        const isInitiallyZoomedIn = maxZoom - boundsZoom <= 2;
        setIsInitiallyZoomedIn(isInitiallyZoomedIn);
        setIsZoomedIn(isInitiallyZoomedIn);

        // Ensure the map updates its size after rendering
        setTimeout(() => {
          newMap.invalidateSize();
          newMap.fitBounds(bounds, {
            padding: [50, 50],
            maxZoom: 18,
          });
        }, 100);

        newMap.on('zoomend moveend', updateRouteDisplay);
        newMap.on('click', handleMapClick);
        newMap.on('mousedown', handleMapMouseDown); // Add this line

        setMap(newMap);
      }
    },
    [locations, createCustomIcon, updateRouteDisplay]
  );

  const toggleRouteVisibility = useCallback(() => {
    handlePlayPause(false); // Add this line
    setIsRouteVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    if (map) {
      createRoute();
      updateRouteDisplay();
    }
  }, [map, createRoute, updateRouteDisplay]);

  const animateMarker = (startLocation, endLocation, duration) => {
    return new Promise((resolve) => {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);

        const lat = startLocation.latitude + (endLocation.latitude - startLocation.latitude) * progress;
        const lng = startLocation.longitude + (endLocation.longitude - startLocation.longitude) * progress;

        if (markerRef.current && Number.isFinite(lat) && Number.isFinite(lng)) {
          markerRef.current.setLatLng([lat, lng]);
        }

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          resolve();
        }
      };

      requestAnimationFrame(animate);
    });
  };

  function playAnimation() {
    if (locations.length < 2 || events.length === 0) {
      return;
    }

    const startTime = new Date(events[0].timestamp).getTime();
    const endTime = new Date(
      Math.max(
        new Date(events[events.length - 1].timestamp).getTime(),
        new Date(locations[locations.length - 1].time).getTime()
      )
    ).getTime();
    const totalDuration = endTime - startTime;
    const animationDuration = 10000; // 10 seconds for full playback

    let currentIndex = 0;
    let lastAnimationTimestamp = 0;

    const animate = async (timestamp) => {
      if (!isPlayingRef.current) {
        return;
      }

      if (lastAnimationTimestamp === 0) {
        lastAnimationTimestamp = timestamp;
      }

      const elapsedTime = timestamp - lastAnimationTimestamp;
      const progress = elapsedTime / animationDuration;

      if (currentIndex < locations.length - 1) {
        const startLocation = locations[currentIndex];
        const endLocation = locations[currentIndex + 1];

        const segmentDuration = new Date(endLocation.time).getTime() - new Date(startLocation.time).getTime();
        const animationSegmentDuration = (segmentDuration / totalDuration) * animationDuration;

        await animateMarker(startLocation, endLocation, animationSegmentDuration);

        setCurrentTime(new Date(endLocation.time));
        currentIndex++;

        animationFrameRef.current = requestAnimationFrame(animate);
      } else {
        // Animation finished, reset to start and stop playing
        setCurrentTime(new Date(events[0].timestamp));
        if (markerRef.current) {
          markerRef.current.setLatLng([locations[0].latitude, locations[0].longitude]);
        }
        handlePlayPause(false);
      }
    };
    animationFrameRef.current = requestAnimationFrame(animate);
  }

  const handleMapClick = () => {
    handlePlayPause(false);
  };

  const handleMapMouseDown = () => {
    handlePlayPause(false);
  };

  const getLocationAtTime = useCallback(
    (time) => {
      if (!locationsLoaded.current || locations.length === 0) {
        return null;
      }

      const targetTime = time.getTime();
      let closestLocation = locations[0];
      let smallestTimeDiff = Math.abs(new Date(locations[0].time).getTime() - targetTime);

      for (let i = 1; i < locations.length; i++) {
        const locationTime = new Date(locations[i].time).getTime();
        const timeDiff = Math.abs(locationTime - targetTime);

        if (timeDiff < smallestTimeDiff) {
          closestLocation = locations[i];
          smallestTimeDiff = timeDiff;
        }

        if (locationTime > targetTime) break;
      }

      console.log('time: ', time, ' smallestTimeDiff: ', smallestTimeDiff, ' closestLocation: ', closestLocation);

      // Ensure that the closestLocation has valid latitude and longitude
      if (closestLocation && Number.isFinite(closestLocation.latitude) && Number.isFinite(closestLocation.longitude)) {
        return closestLocation;
      } else {
        return null;
      }
    },
    [locations]
  );

  useEffect(() => {
    if (map && polylineRef.current) {
      if (showFullRoute) {
        polylineRef.current.addTo(map);
      } else {
        polylineRef.current.remove();
      }
    }
  }, [map, showFullRoute]);

  const toggleFullRoute = () => {
    setShowFullRoute((prev) => !prev);
  };

  useEffect(() => {
    if (event) {
      setCurrentTime(new Date(event.timestamp));
      setEventMarkerTime(new Date(event.timestamp));
      animateToTime(new Date(event.timestamp));
    }
  }, [event]);

  const animateToTime = (targetTime) => {
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    const startTime = currentTime.getTime();
    const endTime = targetTime.getTime();
    const duration = 100; // Animation duration in milliseconds
    const startTimestamp = performance.now();

    const startLocation = getLocationAtTime(currentTime);
    const endLocation = getLocationAtTime(targetTime);
    console.log(
      'startLocation: ',
      startLocation,
      ' currentTime: ',
      currentTime,
      ' endLocation: ',
      endLocation,
      ' targetTime: ',
      targetTime
    );

    if (!startLocation || !endLocation) {
      console.warn('Invalid start or end location for animation.');
      return;
    }

    const animate = (timestamp) => {
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const currentTimeValue = startTime + (endTime - startTime) * progress;

      setCurrentTime(new Date(currentTimeValue));

      const lat = startLocation.latitude + (endLocation.latitude - startLocation.latitude) * progress;
      const lng = startLocation.longitude + (endLocation.longitude - startLocation.longitude) * progress;

      if (markerRef.current && Number.isFinite(lat) && Number.isFinite(lng)) {
        markerRef.current.setLatLng([lat, lng]);
      } else {
        console.warn('Invalid latitude or longitude during animation.');
      }

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animate);
      } else {
        setIsAnimating(false);
        // Check if there's a new target time
        if (targetTimeRef.current && targetTimeRef.current.getTime() !== endTime) {
          animateToTime(targetTimeRef.current);
        }
      }
    };

    setIsAnimating(true);
    animationRef.current = requestAnimationFrame(animate);
  };

  const handleSliderChange = (event) => {
    handlePlayPause(false);
    if (locations.length === 0 || events.length === 0) return;
    const startTime = new Date(events[0].timestamp);
    const endTime = new Date(
      Math.max(
        new Date(events[events.length - 1].timestamp).getTime(),
        new Date(locations[locations.length - 1].time).getTime()
      )
    );
    const totalDuration = endTime - startTime;
    const progress = Number(event.target.value) / 100;
    const newTime = new Date(startTime.getTime() + totalDuration * progress);

    const currentLocation = getLocationAtTime(currentTime);
    const newLocation = getLocationAtTime(newTime);

    if (
      currentLocation &&
      newLocation &&
      markerRef.current &&
      Number.isFinite(currentLocation.latitude) &&
      Number.isFinite(currentLocation.longitude) &&
      Number.isFinite(newLocation.latitude) &&
      Number.isFinite(newLocation.longitude)
    ) {
      animateMarker(currentLocation, newLocation, 100); // 100ms animation duration
    } else {
      console.warn('Invalid location data during slider change.');
    }

    setCurrentTime(newTime);
  };

  // useEffect(() => {
  //   if (!isAnimating && targetTimeRef.current) {
  //     const timeDiff = Math.abs(currentTime.getTime() - targetTimeRef.current.getTime());
  //     if (timeDiff > 50) {
  //       // If the difference is significant, animate to the target time
  //       animateToTime(targetTimeRef.current);
  //     } else {
  //       setCurrentTime(targetTimeRef.current);
  //     }
  //     targetTimeRef.current = null;
  //   }
  // }, [isAnimating, currentTime]);

  const handlePlayPause = (newValue = undefined) => {
    setIsPlaying((prevIsPlaying) => {
      const nextIsPlaying = newValue === undefined ? !prevIsPlaying : newValue;

      isPlayingRef.current = nextIsPlaying;

      if (nextIsPlaying && locationsLoaded) {
        // Start animation only if locations are loaded and we're playing
        if (!animationFrameRef.current) {
          playAnimation();
        }
      } else {
        // Stop all animations
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current);
          animationFrameRef.current = null;
        }
      }

      return nextIsPlaying;
    });
  };

  const formatTime = (date) => {
    const timeString = new Date(date).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' });
    const [time, period] = timeString.split(' ');
    return (
      <div className="time-display">
        <span>{time}</span>
        <br />
        <span className="period">{period}</span>
      </div>
    );
  };

  const formatTimeStr = (date) => {
    return new Date(date).toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const toggleZoom = () => {
    if (map && markerRef.current) {
      handlePlayPause(false); // Add this line
      const bounds = L.latLngBounds(locations.map((loc) => [loc.latitude, loc.longitude]));
      const maxZoom = map.getMaxZoom();
      const boundsZoom = map.getBoundsZoom(bounds);

      if (isInitiallyZoomedIn) {
        if (isZoomedIn) {
          // Zoom out 4 levels from the bounds zoom
          const targetZoom = Math.max(0, boundsZoom - 4);
          map.flyToBounds(bounds, {
            padding: [50, 50],
            maxZoom: targetZoom,
            duration: 1,
            easeLinearity: 0.25,
          });
        } else {
          // Zoom in to show full route
          map.flyToBounds(bounds, {
            padding: [50, 50],
            maxZoom: maxZoom,
            duration: 1,
            easeLinearity: 0.25,
          });
        }
      } else {
        if (isZoomedIn) {
          // Zoom out to show full route
          map.flyToBounds(bounds, {
            padding: [50, 50],
            maxZoom: maxZoom,
            duration: 1,
            easeLinearity: 0.25,
          });
        } else {
          // Zoom in to marker
          map.flyTo(markerRef.current.getLatLng(), maxZoom, {
            duration: 1,
            easeLinearity: 0.25,
          });
        }
      }
      setIsZoomedIn(!isZoomedIn);
    }
  };

  const generateTimeMarkers = useCallback(
    (locs, events) => {
      if (locs.length === 0 || events.length === 0) return [];

      const startEvent = events[0];
      const endEvent = events[events.length - 1];

      if (!startEvent || !endEvent) return [];

      const startTime = new Date(startEvent.timestamp);
      const endTime = new Date(
        Math.max(
          new Date(events[events.length - 1].timestamp).getTime(),
          new Date(locs[locs.length - 1].time).getTime()
        )
      );
      const totalDurationMs = endTime - startTime;
      const totalDurationHours = totalDurationMs / (1000 * 60 * 60);

      // Determine the number of markers based on the container width
      const containerWidth = size === 'large' ? 550 : 250;
      const markerWidth = 50; // Approximate width of each marker
      const maxMarkers = Math.floor(containerWidth / markerWidth) - 2; // Subtract 2 to leave space for start and end markers

      let intervalMinutes;
      if (totalDurationHours <= maxMarkers / 6) {
        intervalMinutes = 10;
      } else if (totalDurationHours <= maxMarkers / 2) {
        intervalMinutes = 30;
      } else {
        intervalMinutes = Math.ceil((totalDurationHours * 60) / maxMarkers / 30) * 30; // Round up to nearest 30 minutes
      }

      const intervalMs = intervalMinutes * 60 * 1000;
      const markers = [];

      let currentMarkerTime = new Date(startTime.getTime() + intervalMs); // Start from the second interval

      while (currentMarkerTime < endTime) {
        const position = ((currentMarkerTime - startTime) / totalDurationMs) * 100;
        if (position > 5 && position < 95) {
          // Only add markers between 5% and 95% of the timeline
          markers.push({
            time: currentMarkerTime,
            position: position,
          });
        }
        currentMarkerTime = new Date(currentMarkerTime.getTime() + intervalMs);
      }

      // Always include start and end markers
      markers.unshift({ time: startTime, position: 0 });
      markers.push({ time: endTime, position: 100 });

      // If we still have too many markers, reduce them
      if (markers.length > maxMarkers) {
        const step = Math.ceil(markers.length / maxMarkers);
        return markers.filter((_, index) => index % step === 0 || index === 0 || index === markers.length - 1);
      }

      return markers;
    },
    [size]
  );

  const timeMarkers = useMemo(() => generateTimeMarkers(locations, events), [locations, events, generateTimeMarkers]);

  if (!locationsLoaded.current) {
    return (
      <div className={`map-view-container ${size}`}>
        <br />
        Loading locations...
      </div>
    );
  }

  if (locations.length === 0 || events.length === 0) {
    return (
      <div className={`map-view-container ${size}`}>
        <br />
        No locations available.
      </div>
    );
  }

  console.log('events: ', events);
  const startTime = new Date(events[0].timestamp);
  const endTime = new Date(
    Math.max(
      new Date(events[events.length - 1].timestamp).getTime(),
      new Date(locations[locations.length - 1].time).getTime()
    )
  );
  const totalDuration = endTime - startTime;
  const totalHours = Math.ceil(totalDuration / (1000 * 60 * 60));
  const timeMarkerCount = Math.min(13, totalHours + 1); // Limit to 13 markers maximum

  const getEventTitle = (event, index, events) => {
    if (event.type === 'start') {
      return index === 0 ? 'Shift Started' : 'Activated';
    } else if (event.type === 'end') {
      return index === events.length - 1 ? 'Shift Ended' : 'Deactivated';
    } else if (event.type === 'locationEnter') {
      return 'Site';
    } else {
      return event.type.charAt(0).toUpperCase() + event.type.slice(1);
    }
  };

  const handleCalloutClick = () => {
    if (event) {
      handlePlayPause(false);
      setCurrentTime(new Date(event.timestamp));
      animateToTime(new Date(event.timestamp));
    }
  };

  return (
    <div className={`map-view-container ${size}`}>
      <div className="map-container" style={{ height: size === 'large' ? '550px' : '250px' }}>
        <div className="map" ref={displayMap} />
        <div className="map-overlay">
          <div className="map-controls-top">
            <button className={`map-button ${isZoomedIn ? 'active' : ''}`} onClick={toggleZoom}>
              {isZoomedIn ? 'Zoom Out' : 'Zoom In'}
            </button>
            <button className={`map-button ${isRouteVisible ? 'active' : ''}`} onClick={toggleRouteVisibility}>
              {isRouteVisible ? 'Hide Route' : 'Show Route'}
            </button>
            <button className="map-button circular" onClick={() => handlePlayPause(undefined)}>
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </button>
          </div>
        </div>

        <div className="time-display-container">
          {currentTime ? (
            <>
              <div className="analog-clock">
                <AnalogClock time={currentTime} />
              </div>
              <div className="digital-time">{formatTimeStr(currentTime)}</div>
            </>
          ) : (
            'No time available'
          )}
        </div>
      </div>
      <div className="map-controls-bottom">
        <div className="slider-container">
          <input
            type="range"
            min="0"
            max="100"
            value={(((currentTime || startTime) - startTime) / totalDuration) * 100}
            onChange={handleSliderChange}
          />
          {eventMarkerPosition !== null && (
            <div
              className="event-marker-callout"
              style={{
                left: `${eventMarkerPosition}%`,
              }}
              onClick={handleCalloutClick}
            >
              {getEventTitle(
                event,
                events.findIndex((e) => e.timestamp === event.timestamp),
                events
              )}
            </div>
          )}
        </div>
        <div className="time-markers">
          {timeMarkers.map((marker, index) => (
            <span
              key={index}
              style={{
                left: `${marker.position}%`,
                transform:
                  index === 0
                    ? 'translateX(0)'
                    : index === timeMarkers.length - 1
                      ? 'translateX(-100%)'
                      : 'translateX(-50%)',
              }}
            >
              {formatTime(marker.time)}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MapView;
