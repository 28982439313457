import axios from 'axios';

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import { useDeviceFilter } from '../contexts/DeviceFilterContext';
import { useAuth0 } from '@auth0/auth0-react';
import Tooltip from '@mui/material/Tooltip';
import InfiniteScroll from 'react-infinite-scroll-component';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/LivePopup.css';

import MetricsDisplay from './MetricsDisplay';
import MobilePanel from './MobilePanel';
import Card from './Card';
import Dashboard from './Dashboard';
import VideoManagement from './VideoManagement';
import DeviceAssignment from './DeviceAssignment';
import SiteAssignment from './SiteAssignment';
import MapViewMain from './MapViewMain';

import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';

import { fetchRecentEscalationAlerts, getAlertLabelingMetrics } from '../utils/cosmosQueries';
import { fetchLocationAtTimestamp } from '../utils/dashboardTimescaleQueries';
import { fetchGeofencesForOrganization } from '../utils/dashboardTimescaleQueries';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSync,
  faBell,
  faVolumeUp,
  faChevronDown,
  faCompass,
  faShield,
  faSignOutAlt,
  faMapMarkerAlt,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

const ALERT_REFRESH_INTERVAL = 60 * 1000;
const ALERTS_PER_PAGE = 20;

function RealTimeMap({ userId, config }) {
  const { user, logout } = useAuth0();
  const [showLogoutButton, setShowLogoutButton] = useState(false);

  const { sidebarOption } = useParams();
  const { devices, selectedOrg, deviceShifts, updateDeviceAssignment } = useDeviceFilter();
  const memoizedDevices = useMemo(() => devices, [devices]);
  const prevSelectedOrgRef = useRef(selectedOrg);
  const [cardsToDisplay, setCardsToDisplay] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalDevice, setModalDevice] = useState(null);
  const [currentLocation, setCurrentLocation] = useState([40.075044252652596, -98.80344386500497]);
  const [dotIcon, setDotIcon] = useState(faVolumeUp);
  const [isCollapsed, setIsCollapsed] = useState({
    devices: false,
    alerts: false,
  });
  const [activeDevice, setActiveDevice] = useState(null);
  const [hasNewAlerts, setHasNewAlerts] = useState(false);
  const [streamingDevices, setStreamingDevices] = useState({});
  const [keepAliveIntervals, setKeepAliveIntervals] = useState({});

  const [alerts, setAlerts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedGeofence, setSelectedGeofence] = useState(null);

  const markerRefs = useRef({});
  const pageLoadTime = useRef(new Date().toISOString());
  const mapRef = useRef(null);
  const firstDeviceSelected = useRef(false);
  // const latestAlertTimestamp = useRef(null);

  const firstMapFit = useRef(false);

  const [activeAlert, setActiveAlert] = useState(null);
  const [alertMarker, setAlertMarker] = useState(null);
  const alertMarkerRef = useRef(null);

  // Metrics

  const [deviceMetrics, setDeviceMetrics] = useState(null);
  const [labelingMetrics, setLabelingMetrics] = useState({ coverage: null, accuracy: null });
  const calculateDeviceMetrics = useCallback(() => {
    const thirteenDaysAgo = new Date();
    thirteenDaysAgo.setDate(thirteenDaysAgo.getDate() - 13);

    const activeDevices = memoizedDevices.filter((device) => {
      const lastShiftTime = new Date(device.lastShiftTime);
      return lastShiftTime >= thirteenDaysAgo;
    });

    setDeviceMetrics({
      numerator: activeDevices.length,
      denominator: memoizedDevices.length,
    });
  }, [memoizedDevices]);

  // Geofences

  const [activeTab, setActiveTab] = useState('devices');
  const [geofences, setGeofences] = useState([]);
  const fetchAndLogGeofences = useCallback(async () => {
    const orgId =
      userId === PLIX_OVERWATCH_ACCOUNT ? (selectedOrg === PLIX_OVERWATCH_ACCOUNT ? undefined : selectedOrg) : userId;
    try {
      const fetchedGeofences = await fetchGeofencesForOrganization(orgId);
      setGeofences(fetchedGeofences);
      console.log('Fetched geofences:', fetchedGeofences);
    } catch (error) {
      console.error('Error fetching geofences:', error);
    }
  }, [userId, selectedOrg]);
  useEffect(() => {
    fetchAndLogGeofences();
  }, [fetchAndLogGeofences]);
  const handleGeofenceUpdate = useCallback((updatedGeofences) => {
    setGeofences(updatedGeofences);
  }, []);

  // Mobile panel

  const [isMobilePanelExpanded, setIsMobilePanelExpanded] = useState(false);
  const handleCloseMobilePanel = useCallback(() => {
    setIsMobilePanelExpanded(false);
  }, []);
  useEffect(() => {
    const handleMapClick = () => {
      handleCloseMobilePanel();
    };

    if (mapRef.current) {
      mapRef.current.on('click', handleMapClick);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', handleMapClick);
      }
    };
  }, [handleCloseMobilePanel]);
  const handleToggleMobilePanel = useCallback((isExpanded) => {
    setIsMobilePanelExpanded(isExpanded);
  }, []);

  // Logout button

  const handleLogoClick = (e) => {
    e.stopPropagation();
    setShowLogoutButton(!showLogoutButton);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showLogoutButton && !event.target.closest('.mobile-user-controls')) {
        setShowLogoutButton(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showLogoutButton]);

  // Fit map

  const fitMapToMarkers = useCallback(
    (mpRef) => {
      console.log('fitMapToMarkers: mpRef: ', mpRef, ' firstMapFit.current: ', firstMapFit.current);
      if (mpRef && memoizedDevices.length > 0) {
        const validDevices = memoizedDevices.filter(
          (device) => Math.abs(device.location[0]) > 0.1 && Math.abs(device.location[1]) > 0.1
        );
        console.log('validDevices: ', validDevices);
        if (validDevices.length > 0) {
          const bounds = L.latLngBounds(validDevices.map((device) => device.location));
          const center = bounds.getCenter();
          const mapSize = mpRef.getSize();

          // Calculate the size of the bounds
          const northEast = bounds.getNorthEast();
          const southWest = bounds.getSouthWest();
          const latDiff = northEast.lat - southWest.lat;
          const lngDiff = northEast.lng - southWest.lng;

          // Calculate the zoom level
          const WORLD_DIM = { height: 256, width: 256 };
          const ZOOM_MAX = 18;

          function latRad(lat) {
            const sin = Math.sin((lat * Math.PI) / 180);
            const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
          }

          function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
          }

          const latFraction = (latRad(northEast.lat) - latRad(southWest.lat)) / Math.PI;
          const lngDiff1 = lngDiff;
          const lngFraction = (lngDiff1 < 0 ? lngDiff1 + 360 : lngDiff1) / 360;

          const latZoom = zoom(mapSize.y, WORLD_DIM.height, latFraction);
          const lngZoom = zoom(mapSize.x, WORLD_DIM.width, lngFraction);

          const zoomLvl = Math.min(latZoom, lngZoom, ZOOM_MAX);

          console.log(`Map fit computation:
        Valid devices: ${validDevices.length}
        Bounds: ${bounds.toBBoxString()}
        Center: ${center.lat.toFixed(4)}, ${center.lng.toFixed(4)}
        Optimal zoom: ${zoomLvl.toFixed(2)}
        Map size: ${mapSize.x}x${mapSize.y}
        Lat diff: ${latDiff.toFixed(2)}, Lng diff: ${lngDiff.toFixed(2)}`);

          mpRef.setView(center, zoomLvl, { animate: false });
          firstMapFit.current = true;
        }
      }
    },
    [memoizedDevices, selectedOrg]
  );

  console.log('fitMapToMarkers outside: selectedOrg changed to: ', selectedOrg);

  useEffect(() => {
    if (selectedOrg !== prevSelectedOrgRef.current) {
      console.log('fitMapToMarkers: selectedOrg changed from', prevSelectedOrgRef.current, 'to', selectedOrg);
      if (mapRef.current) {
        fitMapToMarkers(mapRef.current);
        prevSelectedOrgRef.current = selectedOrg;
      }
    }
  }, [selectedOrg, fitMapToMarkers]);

  useEffect(() => {
    /**
     * Sets the current location to the location of the first device and centers the map on it.
     */
    console.log(memoizedDevices);
    const interval = setInterval(() => {
      if (memoizedDevices.length > 0 && !firstDeviceSelected.current) {
        // const firstDeviceLocation = memoizedDevices[0].location;
        // setCurrentLocation(firstDeviceLocation);
        const marker = markerRefs.current[memoizedDevices[0].deviceId];

        firstDeviceSelected.current = true;

        if (marker && marker._map) {
          console.log('fitMapToMarkers: because firstDeviceSelected was false');
          fitMapToMarkers(marker._map);
          // marker._map.flyTo(firstDeviceLocation, 18, { animate: false });
          // setCurrentLocation(firstDeviceLocation);
          // firstDeviceSelected.current = true;
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [memoizedDevices]);

  useEffect(() => {
    // Reset streaming devices when the component mounts
    setStreamingDevices({});
  }, []);

  const [fetchError, setFetchError] = useState(null);

  const [continuationToken, setContinuationToken] = useState(null);

  const updateAlerts = useCallback(async () => {
    console.log('updateAlerts with devices: ', memoizedDevices, 'activeDevice: ', activeDevice);
    if (memoizedDevices.length === 0) return;

    const deviceIds = activeDevice ? [activeDevice] : memoizedDevices.map((d) => d.deviceId);
    console.log('deviceIds: ', deviceIds);

    try {
      const {
        alerts: fetchedAlerts,
        hasMore,
        continuationToken: newToken,
        error,
      } = await fetchRecentEscalationAlerts(userId, deviceIds, null, ALERTS_PER_PAGE);

      if (error) {
        setFetchError(error);
        return;
      }

      console.log('updateAlerts: ', fetchedAlerts);
      // Ensure we always set an array, even if it's empty
      // This prevents the error boundry from throwing red screens for new accounts
      setAlerts(fetchedAlerts || []);
      setHasMore(hasMore);
      setContinuationToken(newToken);
      setHasNewAlerts(false);
      setFetchError(null);

      // Fetch and set labeling metrics
      const metrics = await getAlertLabelingMetrics(userId, deviceIds);
      setLabelingMetrics(metrics);
    } catch (error) {
      console.error('Error updating alerts:', error);
      setFetchError('Failed to update alerts. Please check your network connection and refresh the page.');
    }
  }, [userId, memoizedDevices, activeDevice]);

  useEffect(() => {
    console.log('Effect triggered, activeDevice:', activeDevice);
    updateAlerts();
    calculateDeviceMetrics();
    const interval = setInterval(() => {
      updateAlerts();
      calculateDeviceMetrics();
    }, ALERT_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [activeDevice, updateAlerts, calculateDeviceMetrics]);

  const loadMoreAlerts = async () => {
    if (!hasMore) return;

    const deviceIds = activeDevice ? [activeDevice] : memoizedDevices.map((d) => d.deviceId);
    const {
      alerts: newAlerts,
      hasMore: newHasMore,
      continuationToken: newToken,
    } = await fetchRecentEscalationAlerts(userId, deviceIds, continuationToken, ALERTS_PER_PAGE);

    setAlerts((prevAlerts) => [...prevAlerts, ...newAlerts]);
    setHasMore(newHasMore);
    setContinuationToken(newToken);
  };

  const sendMessageToDevice = async (deviceId, command) => {
    /**
     * Sends a message to the device to start or stop the livestream.
     * The message is sent to the Azure function which sends the message to the device's FCM token.
     */
    const azureFunctionUrl = 'https://plixnotifyfirebase.azurewebsites.net/api/notifyfirebase';
    // const azureFunctionUrl = 'https://plixnotifyfirebase';
    // Look up the device in the devices context to find the fcmToken
    const device = memoizedDevices.find((d) => d.deviceId === deviceId);
    const fcmToken = device.fcmToken;
    console.log('DEVICE:', device);
    console.log('SENDING MESSAGE:', command);
    console.log('FCM TOKEN BEING SENT:', fcmToken);

    try {
      const response = await axios.post(azureFunctionUrl, { fcmToken, command });
      console.log('Successfully sent message:', response.data);
    } catch (error) {
      console.error('Error sending message:', error.response ? error.response.data : error.message);
    }
  };

  const sendKeepAlive = async (deviceId) => {
    /**
     * Sends a keep-alive message to the device to keep the livestream alive.
     * @param {string} deviceId - The ID of the device to send the keep-alive message to.
     */

    try {
      await sendMessageToDevice(deviceId, 'KEEP ALIVE');
      console.log('Sent keep-alive message for device:', deviceId);
    } catch (error) {
      console.error('Failed to send keep-alive message:', error);
    }
  };

  const handleToggleStream = (deviceId) => {
    setStreamingDevices((prev) => {
      /**
       * Toggles the streaming status of the specified deviceId.
       * Sends a START LIVESTREAM message to the device to start the livestream.
       * Starts a keep-alive interval to keep the livestream alive.
       * @param {string} deviceId - The ID of the device to toggle the streaming status for.
       * @returns {object} - The new state object with the streaming status for the specified deviceId.
       */
      const newState = { ...prev, [deviceId]: !prev[deviceId] };
      if (newState[deviceId]) {
        sendMessageToDevice(deviceId, 'START LIVESTREAM');

        // Start keep-alive interval
        const intervalId = setInterval(() => sendKeepAlive(deviceId), 55000); // 55 seconds
        setKeepAliveIntervals((prevIntervals) => ({
          ...prevIntervals,
          [deviceId]: intervalId,
        }));
      } else {
        // Clear keep-alive interval
        if (keepAliveIntervals[deviceId]) {
          clearInterval(keepAliveIntervals[deviceId]);
          setKeepAliveIntervals((prevIntervals) => {
            const newIntervals = { ...prevIntervals };
            delete newIntervals[deviceId];
            return newIntervals;
          });
        }
      }
      return newState;
    });
  };

  const handleDeviceClick = useCallback((deviceId, location) => {
    console.log('Device clicked:', deviceId);
    setCurrentLocation(location);
    firstDeviceSelected.current = true;

    // Reset z-index and remove active class from all markers
    Object.values(markerRefs.current).forEach((marker) => {
      if (marker && marker.setZIndexOffset) {
        marker.setZIndexOffset(0);
      }
    });

    // Set the clicked marker as active and bring it to the front
    const marker = markerRefs.current[deviceId];
    if (marker) {
      marker.openPopup();
      if (marker.setZIndexOffset) {
        marker.setZIndexOffset(1000);
      }
    }

    // Center the map on the clicked device's location
    const map = marker._map;
    if (map) {
      map.flyTo(location, 18, { animate: false });
    }
  }, []);

  const handleCloseAlertPopup = useCallback(() => {
    setActiveAlert(null);
    setAlertMarker(null);
    if (alertMarkerRef.current) {
      alertMarkerRef.current.remove();
    }
  }, []);

  const handleCardClick = useCallback(
    async (alert) => {
      if (mapRef.current) {
        const location = await fetchLocationAtTimestamp(alert.DeviceId, alert.Timestamp);
        console.log('location: ', location);
        if (location) {
          // Close any existing alert popup
          handleCloseAlertPopup();

          setActiveAlert({ ...alert, location });
          setAlertMarker(location);
          mapRef.current.flyTo(location, 18, { animate: false });
          setTimeout(() => {
            if (alertMarkerRef.current) {
              alertMarkerRef.current.openPopup();
            }
          }, 100);
          handleCloseMobilePanel();
        }
      }
    },
    [handleCloseAlertPopup, handleCloseMobilePanel]
  );

  const handleDeviceSelect = useCallback(
    (deviceId) => {
      const device = memoizedDevices.find((d) => d.deviceId === deviceId);
      console.log('Device selected:', device);
      if (device) {
        handleDeviceClick(deviceId, device.location);
        handleCloseMobilePanel();
      }
    },
    [memoizedDevices, handleDeviceClick, updateAlerts]
  );

  const handleSiteClick = useCallback((latitude, longitude) => {
    if (mapRef.current) {
      mapRef.current.flyTo([latitude, longitude], 18, { animate: true });
    }
  }, []);

  const handleClosePopup = (deviceId) => {
    /**
     * Handles the close popup by setting the device filter, active device, and streaming devices.
     * @param {string} deviceId - The ID of the device to stop streaming.
     */
    setActiveDevice(null);
    setStreamingDevices((prev) => ({ ...prev, [deviceId]: false }));
    // Clear keep-alive interval
    if (keepAliveIntervals[deviceId]) {
      clearInterval(keepAliveIntervals[deviceId]);
      setKeepAliveIntervals((prevIntervals) => {
        const newIntervals = { ...prevIntervals };
        delete newIntervals[deviceId];
        return newIntervals;
      });
    }
  };

  const toggleCollapse = (containerType, tab = null) => {
    setIsCollapsed((prevState) => {
      if (containerType === 'devices') {
        if (tab && tab !== activeTab) {
          setActiveTab(tab);
          return { ...prevState, [containerType]: false };
        } else {
          return { ...prevState, [containerType]: !prevState[containerType] };
        }
      }
      return { ...prevState, [containerType]: !prevState[containerType] };
    });
  };

  const handleOpenPopup = useCallback(
    (deviceId) => {
      console.log('Popup opened for device:', deviceId);
      setActiveDevice(deviceId);
      handleCloseMobilePanel();
    },
    [updateAlerts]
  );

  const NoAlertsMessage = () => (
    <div className="no-alerts-message">
      <FontAwesomeIcon icon={faShield} size="3x" color="#d3d3d3" />
      <p>
        No escalations detected yet
        {activeDevice && memoizedDevices.find((d) => d.deviceId === activeDevice)
          ? ` for ${memoizedDevices.find((d) => d.deviceId === activeDevice).assignedTo || activeDevice}`
          : ''}
      </p>
    </div>
  );

  const [previewGeofence, setPreviewGeofence] = useState(null);

  const handlePreviewGeofence = useCallback((geofence) => {
    setPreviewGeofence(geofence);
  }, []);

  const handlePreviewGeofenceRadiusChange = useCallback((newRadius) => {
    setPreviewGeofence((prevGeofence) => {
      if (prevGeofence) {
        return { ...prevGeofence, radius: newRadius };
      }
      return null;
    });
  }, []);

  const renderContent = () => {
    /**
     * Renders the content of the real-time map.
     */
    console.log('devices in real time map', memoizedDevices);

    switch (sidebarOption) {
      case 'dashboard':
        return <Dashboard transitionsConfig={config.transitionsConfig} userId={userId} />;
      case 'video':
        return <VideoManagement />;
      default:
        return (
          <div style={{ flex: 1, position: 'relative' }}>
            <div className="mobile-user-controls">
              <div className="mobile-user-logo" onClick={handleLogoClick}>
                {user.email.endsWith('cpgsecure.com') ? (
                  <img className="user-avatar-logo" src="/logos/cpg_logo.webp" alt="User Logo" />
                ) : (
                  <img className="user-avatar" src={user.picture} alt="User Avatar" />
                )}
              </div>
              <button
                className={`mobile-logout-button ${showLogoutButton ? 'show' : ''}`}
                onClick={handleLogout}
                style={{
                  transform: showLogoutButton ? 'translateX(-50%) translateY(0)' : 'translateX(-50%) translateY(-10px)',
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} /> Logout
              </button>
            </div>
            <MapViewMain
              memoizedDevices={memoizedDevices}
              deviceShifts={deviceShifts}
              handleToggleStream={handleToggleStream}
              streamingDevices={streamingDevices}
              mapRef={mapRef}
              markerRefs={markerRefs}
              alertMarkerRef={alertMarkerRef}
              activeDevice={activeDevice}
              activeAlert={activeAlert}
              alertMarker={alertMarker}
              handleOpenPopup={handleOpenPopup}
              handleClosePopup={handleClosePopup}
              handleCloseAlertPopup={handleCloseAlertPopup}
              setModalDevice={setModalDevice}
              setShowModal={setShowModal}
              geofences={geofences}
              userId={userId}
              previewGeofence={previewGeofence}
              selectedGeofence={selectedGeofence}
            />
            <MetricsDisplay
              metrics={labelingMetrics}
              deviceMetrics={deviceMetrics}
              isOverwatch={userId === PLIX_OVERWATCH_ACCOUNT}
            />
            <div className={`devices-container ${isCollapsed.devices ? 'collapsed' : 'expanded'}`}>
              <div className="devices-header">
                <div
                  className={`tab ${activeTab === 'devices' ? 'active' : ''}`}
                  onClick={() => toggleCollapse('devices', 'devices')}
                >
                  <FontAwesomeIcon icon={faCompass} style={{ color: 'red', marginRight: '5px' }} />
                  <h3 style={{ margin: 0 }}>Devices</h3>
                </div>
                <div
                  className={`tab ${activeTab === 'sites' ? 'active' : ''}`}
                  onClick={() => toggleCollapse('devices', 'sites')}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'red', marginRight: '5px' }} />
                  <h3 style={{ margin: 0 }}>Sites</h3>
                </div>
              </div>
              <div className="content">
                {activeTab === 'devices' ? (
                  <DeviceAssignment
                    handleDeviceClick={handleDeviceClick}
                    devices={memoizedDevices}
                    deviceShifts={deviceShifts}
                    updateDeviceAssignment={updateDeviceAssignment}
                  />
                ) : (
                  <div className="sites-content">
                    <SiteAssignment
                      geofences={geofences}
                      devices={memoizedDevices}
                      deviceShifts={deviceShifts}
                      onSiteClick={handleSiteClick}
                      organizationId={userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId}
                      onGeofenceUpdate={handleGeofenceUpdate}
                      handleDeviceSelect={handleDeviceSelect}
                      onPreviewGeofence={handlePreviewGeofence}
                      selectedGeofence={selectedGeofence}
                      setSelectedGeofence={setSelectedGeofence}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={`alerts-container ${isCollapsed.alerts ? 'collapsed' : 'expanded'}`}>
              <div className="alerts-header" onClick={() => toggleCollapse('alerts')}>
                <FontAwesomeIcon icon={faBell} style={{ color: 'red', marginRight: '5px' }} />
                <h3 style={{ flexGrow: 1, margin: 0 }}>Alerts</h3>
                <span className="current-time">{format(new Date(), 'h:mm a')}</span>
              </div>
              <div className="content" id="alertsScrollContainer">
                {fetchError ? (
                  <div className="error-message">
                    <FontAwesomeIcon icon={faExclamationTriangle} className="error-icon" />
                    <p>{'Failed to get alerts. Please try again later.'}</p>
                    <button className="reload-button" onClick={() => window.location.reload()}>
                      <FontAwesomeIcon icon={faSync} />
                    </button>
                  </div>
                ) : alerts && alerts.length > 0 ? (
                  <InfiniteScroll
                    dataLength={alerts.length}
                    next={loadMoreAlerts}
                    hasMore={hasMore}
                    loader={<div className="loader-text">Loading more alerts...</div>}
                    scrollableTarget="alertsScrollContainer"
                  >
                    {alerts.map((alert) => (
                      <Card
                        userId={userId}
                        key={alert.id}
                        initialCard={alert}
                        icon={config.transitionsConfig.find((t) => t.title === alert.Label)?.icon}
                        onDeviceSelect={handleDeviceSelect}
                        onCardClick={handleCardClick}
                      />
                    ))}
                  </InfiniteScroll>
                ) : (
                  <NoAlertsMessage />
                )}
              </div>
            </div>
          </div>
        );
    }
  };

  const refreshButtonClass = hasNewAlerts ? 'refresh-button pulsate' : 'refresh-button';

  useEffect(() => {
    /**
     * Clears all keep-alive intervals when the component unmounts.
     */
    return () => {
      // Clear all keep-alive intervals when component unmounts
      Object.values(keepAliveIntervals).forEach(clearInterval);
    };
  }, [keepAliveIntervals]);

  return (
    <div className="app-container">
      {renderContent()}
      <MobilePanel
        userId={userId}
        devices={memoizedDevices}
        alerts={alerts}
        handleDeviceClick={handleDeviceClick}
        handleCardClick={handleCardClick}
        config={config}
        activeDevice={activeDevice}
        NoAlertsMessage={NoAlertsMessage}
        isExpanded={isMobilePanelExpanded}
        onToggle={handleToggleMobilePanel}
        handleDeviceSelect={handleDeviceSelect}
        deviceShifts={deviceShifts}
        updateDeviceAssignment={updateDeviceAssignment}
        geofences={geofences} // Add this prop
        onSiteClick={handleSiteClick} // Add this prop
        organizationId={userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId} // Add this prop
        onGeofenceUpdate={handleGeofenceUpdate} // Add this prop
        previewGeofence={previewGeofence}
        onPreviewGeofence={handlePreviewGeofence}
        onPreviewGeofenceRadiusChange={handlePreviewGeofenceRadiusChange}
      />
    </div>
  );
}

export default RealTimeMap;
