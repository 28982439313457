// EventInfo.js
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventInfo/EventInfo.css';
import LocationInfo from './LocationInfo';
import RecordingInfo from './RecordingInfo';
import SiteInfo from './SiteInfo';
import SingleMapView from './SingleMapView';
import Transcript from '../../../../../../Transcript';

const EventInfo = ({
  showVideo,
  selectedEvent,
  alertData,
  assignedTo,
  allEvents,
  getPlayerState,
  onTranscriptClick,
  eventTypes,
  shiftEndTime,
  isShiftInProgress,
}) => {
  console.log('alertData', alertData);
  return (
    <div className="event-info-container">
      {selectedEvent.event.type === 'escalation' ? (
        <>
          <div className="event-info-map">
            <SingleMapView location={selectedEvent.event.location} size="small" />
          </div>
          <LocationInfo
            event={selectedEvent}
            assignedTo={assignedTo}
            alertData={alertData}
            allEvents={allEvents}
            showDetails={false}
            eventTypes={eventTypes}
            shiftEndTime={shiftEndTime}
            isShiftInProgress={isShiftInProgress}
          />
          {alertData && (
            <Transcript alert={alertData} getPlayerState={getPlayerState} onTranscriptClick={onTranscriptClick} />
          )}
        </>
      ) : selectedEvent.event.type === 'recordingVideo' ? (
        <RecordingInfo event={selectedEvent.event} assignedTo={assignedTo} />
      ) : selectedEvent.event.type === 'locationEnter' ? (
        <SiteInfo
          event={selectedEvent.event}
          assignedTo={assignedTo}
          shiftEndTime={shiftEndTime}
          isShiftInProgress={isShiftInProgress}
        />
      ) : (
        <LocationInfo
          event={selectedEvent}
          assignedTo={assignedTo}
          alertData={alertData}
          allEvents={allEvents}
          showDetails={true}
          eventTypes={eventTypes}
          shiftEndTime={shiftEndTime}
          isShiftInProgress={isShiftInProgress}
        />
      )}
    </div>
  );
};

export default EventInfo;
