import { cosmosClient } from './eventstorage';
import { getQueryAndParams } from './utilsEvents';
import { PLIX_OVERWATCH_ACCOUNT } from './utilsEvents';
import { subDays } from 'date-fns';
import { toast } from 'react-toastify';

const alertsContainer = cosmosClient.database('Alerts').container('StateMachineAlerts');
const deviceLogsContainer = cosmosClient.database('DeviceLogs').container('DeviceLogs');
const videosContainer = cosmosClient.database('Videos').container('Videos');
const organizationsContainer = cosmosClient.database('Organizations').container('Organizations');
const shiftsContainer = cosmosClient.database('Shifts').container('Shifts');
const devicesContainer = cosmosClient.database('Devices').container('Devices');
const batteryMetricsContainer = cosmosClient.database('Metrics').container('DeviceMetrics');
const locationEventsContainer = cosmosClient.database('Metrics').container('GeofenceEvents');

// Read deployed.json
let deployedModels = [];

// Function to load deployed models
export const loadDeployedModels = async () => {
  try {
    const response = await fetch('/deployed.json');
    const data = await response.json();
    deployedModels = data.deployed_models;
  } catch (error) {
    console.error('Error loading deployed models:', error);
    toast.error('Failed to load deployed models. Please try again later.');
    throw error;
  }
};

export const fetchAllFlaggedShiftsForDevices = async (deviceIds) => {
  try {
    const query = {
      query: 'SELECT * FROM c WHERE c.isFlagged = true AND ARRAY_CONTAINS(@deviceIds, c.deviceId)',
      parameters: [{ name: '@deviceIds', value: deviceIds }],
    };
    const { resources: flaggedShifts } = await shiftsContainer.items.query(query).fetchAll();
    return flaggedShifts;
  } catch (error) {
    console.error('Error fetching flagged shifts:', error);
    toast.error('Failed to fetch flagged shifts. Please try again later.');
    throw error;
  }
};

export const fetchDevicesFromUserId = async (userId) => {
  try {
    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      // Fetch all organizations
      const { resources: orgs } = await organizationsContainer.items.query('SELECT * FROM c').fetchAll();

      // Collect all device IDs from all organizations
      const allDeviceIds = orgs.reduce((acc, org) => {
        return acc.concat(org.deviceIds || []);
      }, []);

      return [{ deviceIds: allDeviceIds }];
    } else {
      // For non-overwatch accounts, fetch devices directly
      const query = {
        query: 'SELECT c.deviceIds FROM c WHERE c.orgUserId = @userId',
        parameters: [{ name: '@userId', value: userId }],
      };
      const { resources: devices } = await organizationsContainer.items.query(query).fetchAll();
      return devices;
    }
  } catch (error) {
    console.error('Error querying devices from Cosmos DB:', error);
    toast.error('Failed to fetch devices. Please try again later.');
    throw error;
  }
};

export const fetchAssignedToFromDeviceIds = async (deviceIds) => {
  try {
    const results = {};
    for (const deviceId of deviceIds) {
      const query = {
        query: 'SELECT c.assignedTo FROM c WHERE c.deviceId = @deviceId',
        parameters: [{ name: '@deviceId', value: deviceId }],
      };
      const { resources: assignedTo } = await devicesContainer.items.query(query).fetchAll();
      if (assignedTo.length > 0) {
        const assignedToValue = assignedTo[0].assignedTo.trim() === '' ? deviceId : assignedTo[0].assignedTo;
        results[deviceId] = assignedToValue;
      } else {
        results[deviceId] = deviceId;
      }
    }
    return results;
  } catch (error) {
    console.error('Error querying assignedTo from Cosmos DB:', error);
    toast.error('Failed to fetch assignedTo. Please try again later.');
    throw error;
  }
};

export const fetchShiftsForDevices = async (userId, deviceIds, page = 1, pageSize = 20) => {
  try {
    // Fetch the organization's creation timestamp
    const orgCreationTimestamp = await fetchOrgCreationTimestamp(userId);

    let query;
    let parameters;

    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      query = `
        SELECT * FROM c
        WHERE ARRAY_CONTAINS(@deviceIds, c.deviceId)
        AND c.startTime >= @orgCreationTimestamp
        AND IS_STRING(c.orgUserId)
        AND (NOT IS_DEFINED(c.duration) OR (c.duration <= 50400 AND c.duration >= 60))
        ORDER BY c.startTime DESC
        OFFSET @offset LIMIT @limit
      `;
      parameters = [
        { name: '@deviceIds', value: deviceIds },
        { name: '@orgCreationTimestamp', value: orgCreationTimestamp },
        { name: '@offset', value: (page - 1) * pageSize },
        { name: '@limit', value: pageSize },
      ];
    } else {
      query = `
        SELECT * FROM c
        WHERE ARRAY_CONTAINS(@deviceIds, c.deviceId)
        AND c.orgUserId = @userId
        AND IS_STRING(c.orgUserId)
        AND c.startTime >= @orgCreationTimestamp
        AND (NOT IS_DEFINED(c.duration) OR (c.duration <= 50400 AND c.duration >= 60))
        ORDER BY c.startTime DESC
        OFFSET @offset LIMIT @limit
      `;
      parameters = [
        { name: '@deviceIds', value: deviceIds },
        { name: '@userId', value: userId },
        { name: '@orgCreationTimestamp', value: orgCreationTimestamp },
        { name: '@offset', value: (page - 1) * pageSize },
        { name: '@limit', value: pageSize },
      ];
    }

    console.log('Executing query:', JSON.stringify({ query, parameters }, null, 2));
    const { resources: shifts } = await shiftsContainer.items.query({ query, parameters }).fetchAll();
    console.log(`Fetched ${shifts.length} shifts for page ${page}`);
    console.log(shifts);
    return shifts;
  } catch (error) {
    console.error('Error querying shifts from Cosmos DB:', error);
    console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
    toast.error('Failed to fetch shifts. Please try again later.');
    throw error;
  }
};

export const fetchOrgCreationTimestamp = async (userId) => {
  try {
    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      // For overwatch, use the earliest timestamp possible
      return new Date(0).toISOString();
    }

    const query = {
      query: 'SELECT c.orgCreationTimestamp FROM c WHERE c.orgUserId = @userId',
      parameters: [{ name: '@userId', value: userId }],
    };
    const { resources } = await organizationsContainer.items.query(query).fetchAll();

    if (resources.length > 0 && resources[0].orgCreationTimestamp) {
      return resources[0].orgCreationTimestamp;
    } else {
      console.warn(`Organization creation timestamp not found for userId: ${userId}. Using default timestamp.`);
      return new Date(0).toISOString(); // Use earliest possible date as fallback
    }
  } catch (error) {
    console.error(`Error fetching orgCreationTimestamp for userId ${userId}:`, error);
    toast.error('Failed to fetch organization creation timestamp. Please try again later.');
    return new Date(0).toISOString(); // Use earliest possible date in case of error
  }
};

/*
 ** Function to process alert data for customer visibility
 ** based on the infra for gradual rollout of new models
 ** This is now a pure function that takes in an alert and the deployed models
 ** and returns a processed alert with the appropriate fields
 ** it's shared between the fetchAlertById and fetchRecentEscalationAlerts functions
 ** the processed alert is then filtered for customer visibility before being returned
 */
const processAlertData = (alert, deployedModels) => {
  let classificationResult = alert.ClassificationResult || {};
  let categorizationResult = alert.CategorizationResult || {};

  // Handle ClassificationResult "true" key
  if (alert.ClassificationLabel && alert.ClassificationLabel !== 'Unlabeled') {
    if (['Correct', 'Escalation'].includes(alert.ClassificationLabel)) {
      classificationResult['true'] = 'Escalation';
    } else if (['Error', 'Not Escalation'].includes(alert.ClassificationLabel)) {
      classificationResult['true'] = 'Not Escalation';
    } else {
      classificationResult['true'] = alert.ClassificationLabel;
    }
  }

  // Handle CategorizationResult "true" key
  if (alert.CategorizationLabel && alert.CategorizationLabel !== 'Unlabeled') {
    categorizationResult['true'] = alert.CategorizationLabel;
  }

  // Add EscalationClass and EscalationCategory fields to results
  Object.entries(alert).forEach(([key, value]) => {
    const isClass = key.startsWith('EscalationClass_');
    const isCat = key.startsWith('EscalationCategory_');
    if (isClass || isCat) {
      const model = key.replace(isClass ? 'EscalationClass_' : 'EscalationCategory_', '');
      const result = isClass ? classificationResult : categorizationResult;
      result[model] = typeof value === 'string' ? value : value?.Label || value;
    }
  });

  if (alert['VideoCategories']) {
    categorizationResult['gpt4o'] = alert['VideoCategories'];
  }
  if (alert['VideoLabel']) {
    classificationResult['gpt4o'] = alert['VideoLabel'];
  }
  if (alert['Label']) {
    classificationResult['gpt4o_pre'] = alert['Label'];
  }

  let label = 'Unlabeled';
  let modelsFired = [];
  let deployedModelUsed = '';

  for (const model of deployedModels) {
    if (model in classificationResult) {
      // if label is still unlabeled, set it to the first model that fired
      if (label === 'Unlabeled') {
        label = classificationResult[model];
      }
      if (!deployedModelUsed || deployedModelUsed === 'true') {
        // if deployedModelUsed is "true", which means it's the human label, we wanna skip it
        deployedModelUsed = model;
      }
      if (classificationResult[model] === 'Escalation') {
        modelsFired.push(model);
      }
    }
  }

  if (label === 'Unlabeled') {
    label = 'Not Escalation';
  }

  // Check if the alert is customer-visible
  const isCustomerVisible = !(alert.IsDeleted || label !== 'Escalation');

  // Handle RelativeTimestamp
  let selectedRelativeTimestamp = alert.RelativeTimestamp;
  for (const model of deployedModels) {
    const key = `RelativeTimestamp_${model}`;
    if (key in alert) {
      selectedRelativeTimestamp = alert[key];
      break;
    }
  }
  alert.RelativeTimestamp = selectedRelativeTimestamp;

  // Handle Description
  let selectedDescription = alert.Description;
  for (const model of deployedModels) {
    const key = `Description_${model}`;
    if (key in alert) {
      selectedDescription = alert[key];
      break;
    }
  }
  alert.Description = selectedDescription;

  // Process Transcripts
  const getSelectedModel = (transcripts) => {
    if (!transcripts || transcripts.length === 0) return null;
    for (const model of deployedModels) {
      const key = `isEscalation_${model}`;
      if (transcripts.some((t) => t[key] === true)) {
        return model;
      }
    }
    return null;
  };

  const processTranscripts = (transcripts, selectedModel) => {
    if (!transcripts) return transcripts;
    return transcripts.map((transcript) => {
      if (selectedModel) {
        const key = `isEscalation_${selectedModel}`;
        transcript.isEscalation = transcript[key] || false;
      } else {
        transcript.isEscalation = transcript.isEscalation || false;
      }
      return transcript;
    });
  };

  const selectedModelForTranscripts = getSelectedModel(alert.Transcripts);
  alert.Transcripts = processTranscripts(alert.Transcripts, selectedModelForTranscripts);

  const selectedModelForVideoTranscripts = getSelectedModel(alert.VideoTranscripts);
  alert.VideoTranscripts = processTranscripts(alert.VideoTranscripts, selectedModelForVideoTranscripts);

  // Update the alert object
  return {
    ...alert,
    Label: label,
    ModelsFired: modelsFired,
    IsCustomerVisible: isCustomerVisible,
    ClassificationResult: classificationResult,
    CategorizationResult: categorizationResult,
    DeployedModelUsed: deployedModelUsed,
  };
};

export const fetchAlertById = async (alertId) => {
  try {
    if (deployedModels.length === 0) {
      await loadDeployedModels();
    }

    const query = {
      query: 'SELECT * FROM c WHERE c.id = @alertId',
      parameters: [{ name: '@alertId', value: alertId }],
    };

    const { resources: alerts } = await alertsContainer.items.query(query).fetchAll();

    if (alerts.length === 0) {
      throw new Error('Alert not found');
    }

    const alert = processAlertData(alerts[0], deployedModels);

    if (!alert.IsCustomerVisible) {
      return null; // Return null if the alert shouldn't be shown to the customer
    }

    return alert;
  } catch (error) {
    console.error('Error fetching alert data:', error);
    throw error;
  }
};

export const queryOrganizationsFromCosmos = async () => {
  try {
    const query = {
      query: 'SELECT * FROM c',
    };
    const { resources: organizations } = await organizationsContainer.items.query(query).fetchAll();
    return organizations;
  } catch (error) {
    console.error('Error querying organizations from Cosmos DB:', error);
    toast.error('Failed to fetch organizations. Please try again later.');
    throw error;
  }
};

export const queryCosmosForEscalation = async (escalation, deviceIds) => {
  try {
    const alertsContainer = cosmosClient.database('Alerts').container('StateMachineAlerts');
    const query = {
      query: `SELECT * FROM c WHERE STARTSWITH(c.id, @prefix) AND ARRAY_CONTAINS(@deviceIds, c.DeviceId)`,
      parameters: [
        { name: '@prefix', value: escalation },
        { name: '@deviceIds', value: deviceIds },
      ],
    };
    const { resources: result } = await alertsContainer.items.query(query).fetchAll();
    return result.length > 0 ? result[0] : null;
  } catch (error) {
    console.error('Error querying Cosmos DB for escalation:', error);
    toast.error('Failed to fetch escalation data. Please try again later.');
    throw error;
  }
};

export const fetchLatestAlertTimestamp = async (userId, selectedDevices) => {
  try {
    const deviceIds = selectedDevices.map((device) => device.deviceId);
    const query = {
      query: `
        SELECT VALUE MAX(c.Timestamp) 
        FROM c 
        WHERE ARRAY_CONTAINS(@deviceIds, c.DeviceId)
        AND IS_DEFINED(c.VideoFileName)
        AND c.VideoFileName != ''
      `,
      parameters: [
        { name: '@userId', value: userId },
        { name: '@deviceIds', value: deviceIds },
      ],
    };
    const { resources } = await alertsContainer.items.query(query).fetchAll();
    console.log('deviceIds: ', deviceIds, ' userId: ', userId, ' resources: ', resources);
    return resources[0];
  } catch (error) {
    console.error('Error in fetchLatestAlertTimestamp:', error);
    toast.error('Failed to fetch latest alert timestamp. Please try again later.');
    throw error;
  }
};

export const fetchEscalations = async (deviceIds, fromDate, toDate) => {
  try {
    const query = {
      query: `
        SELECT * FROM c 
        WHERE (c.Label = 'Escalation' OR c.Label = 'Keyword') 
        AND c.Timestamp >= @fromDate 
        AND c.Timestamp <= @toDate 
        AND ARRAY_CONTAINS(@deviceIds, c.DeviceId)
        AND IS_DEFINED(c.VideoFileName)
        AND c.VideoFileName != ''
      `,
      parameters: [
        { name: '@fromDate', value: fromDate },
        { name: '@toDate', value: toDate },
        { name: '@deviceIds', value: deviceIds },
      ],
    };
    const { resources: escalations } = await alertsContainer.items.query(query).fetchAll();
    return escalations.filter((alert) => !alert.ClassificationLabel || alert.ClassificationLabel !== 'Error');
  } catch (error) {
    console.error('Error fetching escalations:', error);
    toast.error('Failed to fetch escalations. Please try again later.');
    throw error;
  }
};

export const fetchDeviceLogs = async (deviceIds, fromDate, toDate) => {
  try {
    const query = {
      query: `SELECT * FROM c WHERE c.timestamp >= @fromDate AND c.timestamp <= @toDate AND ARRAY_CONTAINS(@deviceIds, c.deviceId) ORDER BY c.Timestamp`,
      parameters: [
        { name: '@fromDate', value: fromDate },
        { name: '@toDate', value: toDate },
        { name: '@deviceIds', value: deviceIds },
      ],
    };
    const { resources: logs } = await deviceLogsContainer.items.query(query).fetchAll();
    return logs;
  } catch (error) {
    console.error('Error fetching device logs:', error);
    toast.error('Failed to fetch device logs. Please try again later.');
    throw error;
  }
};

export const fetchVideos = async (deviceIds, fromDate, toDate) => {
  try {
    const query = {
      query: `SELECT * FROM c WHERE c.TimeRecorded >= @fromDate AND c.TimeRecorded <= @toDate AND ARRAY_CONTAINS(@deviceIds, c.deviceId)`,
      parameters: [
        { name: '@fromDate', value: fromDate },
        { name: '@toDate', value: toDate },
        { name: '@deviceIds', value: deviceIds },
      ],
    };
    const { resources: videos } = await videosContainer.items.query(query).fetchAll();
    return videos;
  } catch (error) {
    console.error('Error fetching videos:', error);
    toast.error('Failed to fetch videos. Please try again later.');
    throw error;
  }
};

export const getRecentAlerts = async (databaseIdAlerts, containerIdAlerts, userId, deviceIds, dateRange) => {
  try {
    const alertsContainer = cosmosClient.database(databaseIdAlerts).container(containerIdAlerts);

    // Add a check for dateRange
    if (!dateRange || dateRange.length < 2) {
      console.error('Invalid dateRange:', dateRange);
      return [];
    }

    const fromDate = dateRange[0].toISOString();
    const toDate = dateRange[1].toISOString();

    console.log('deviceIds: ', deviceIds);
    const baseQuery = `
      SELECT * FROM c 
      WHERE c.userId = @userId 
      AND c.Timestamp >= @fromDate 
      AND c.Timestamp <= @toDate 
      AND ARRAY_CONTAINS(@deviceIds, c.DeviceId)
      AND IS_DEFINED(c.VideoFileName)
      AND c.VideoFileName != ''
      ORDER BY c.Timestamp DESC
    `;
    const { query, parameters } = getQueryAndParams(baseQuery, userId, [
      { name: '@fromDate', value: fromDate },
      { name: '@toDate', value: toDate },
      { name: '@deviceIds', value: deviceIds },
    ]);
    console.log('query: ', query);

    const { resources: alerts } = await alertsContainer.items.query({ query, parameters }).fetchAll();
    return alerts;
  } catch (error) {
    console.error('Failed to fetch alerts with search query:', error);
    if (error.name === 'RestError' && error.message.includes('Failed to fetch')) {
      toast.error('Network error: Please check your internet connection');
    } else {
      toast.error('Failed to fetch alerts. Please try again later.');
    }
    throw error;
  }
};

export const updateAlertClassification = async (alertId, deviceId, newClassification) => {
  try {
    const { resource: alert } = await alertsContainer.item(alertId, deviceId).read();
    if (!alert) {
      throw new Error('Alert not found');
    }

    const updatedAlert = {
      ...alert,
      ClassificationLabel: newClassification,
    };

    const { resource: result } = await alertsContainer.item(alertId, deviceId).replace(updatedAlert);
    return result;
  } catch (error) {
    console.error('Error updating alert classification:', error);
    toast.error('Failed to update alert classification. Please try again later.');
    throw error;
  }
};

export const updateAlertLabels = async (alertId, deviceId, newLabels) => {
  try {
    const { resource: alert } = await alertsContainer.item(alertId, deviceId).read();
    if (!alert) {
      throw new Error('Alert not found');
    }

    const updatedAlert = {
      ...alert,
      ...newLabels,
    };

    const { resource: result } = await alertsContainer.item(alertId, deviceId).replace(updatedAlert);
    return result;
  } catch (error) {
    console.error('Error updating alert labels:', error);
    toast.error('Failed to update alert labels. Please try again later.');
    throw error;
  }
};

export const getAlertMetrics = async (deviceIds, fromDate, toDate) => {
  try {
    const createQuery = (additionalCondition = '') => ({
      query: `
        SELECT VALUE COUNT(1)
        FROM c
        WHERE c.Timestamp >= @fromDate
        AND c.Timestamp <= @toDate
        AND ARRAY_CONTAINS(@deviceIds, c.DeviceId)
        ${additionalCondition}
      `,
      parameters: [
        { name: '@fromDate', value: fromDate },
        { name: '@toDate', value: toDate },
        { name: '@deviceIds', value: deviceIds },
      ],
    });

    const queries = [
      createQuery(), // total
      createQuery("AND IS_DEFINED(c.ClassificationLabel) AND c.ClassificationLabel != 'Unlabeled'"), // labeled
      createQuery("AND c.ClassificationLabel = 'Correct'"), // correct
      createQuery("AND c.ClassificationLabel = 'Error'"), // error
    ];

    const results = await Promise.all(queries.map((query) => alertsContainer.items.query(query).fetchAll()));

    const [total, labeled, correct, error] = results.map((result) => result.resources[0]);

    console.log('Raw counts:', { total, labeled, correct, error });

    const coverage = total > 0 ? Math.round((labeled / total) * 100) : 0;
    const accuracy = labeled > 0 ? Math.round((correct / labeled) * 100) : 0;

    console.log('Calculated metrics:', { coverage, accuracy });

    return {
      coverage,
      accuracy,
      totalCount: total,
      labeledCount: labeled,
      correctCount: correct,
    };
  } catch (error) {
    console.error('Error fetching alert metrics:', error);
    if (error.response) {
      console.error('Error response:', error.response);
    }
    toast.error('Failed to fetch alert metrics. Please try again later.');
    throw error;
  }
};

export const updateShiftFlagStatus = async (id, orgUserId, isFlagged) => {
  try {
    console.log(`updateShiftFlagStatus called with: id=${id}, orgUserId=${orgUserId}, isFlagged=${isFlagged}`);
    const { resource: shift } = await shiftsContainer.item(id, orgUserId).read();
    if (!shift) {
      throw new Error('Shift not found');
    }
    const updatedShift = {
      ...shift,
      isFlagged: isFlagged,
    };
    const { resource: result } = await shiftsContainer.item(id, orgUserId).replace(updatedShift);
    return result;
  } catch (error) {
    console.error('Error updating shift flag status:', error);
    toast.error('Failed to update shift flag status. Please try again later.');
    throw error;
  }
};

export const fetchRecentEscalationAlerts = async (
  userId,
  deviceIds,
  continuationToken = null,
  pageSize = 20,
  retryCount = 3,
  retryDelay = 1000
) => {
  try {
    if (deployedModels.length === 0) {
      await loadDeployedModels();
    }

    const alertsContainer = cosmosClient.database('Alerts').container('StateMachineAlerts');
    const orgCreationTimestamp = await fetchOrgCreationTimestamp(userId);

    let query;
    let parameters;

    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      query = `
        SELECT * FROM c
        WHERE ARRAY_CONTAINS(@deviceIds, c.DeviceId)
        AND c.Timestamp >= @orgCreationTimestamp
        AND IS_DEFINED(c.VideoFileName)
        AND c.VideoFileName != ''
        ORDER BY c.Timestamp DESC
      `;
      parameters = [
        { name: '@deviceIds', value: deviceIds },
        { name: '@orgCreationTimestamp', value: orgCreationTimestamp },
      ];
    } else {
      query = `
        SELECT * FROM c
        WHERE ARRAY_CONTAINS(@deviceIds, c.DeviceId)
        AND c.userId = @userId
        AND c.Timestamp >= @orgCreationTimestamp
        AND IS_DEFINED(c.VideoFileName)
        AND c.VideoFileName != ''
        AND (NOT IS_DEFINED(c.IsDeleted) OR c.IsDeleted = false)
        ORDER BY c.Timestamp DESC
      `;
      parameters = [
        { name: '@deviceIds', value: deviceIds },
        { name: '@userId', value: userId },
        { name: '@orgCreationTimestamp', value: orgCreationTimestamp },
      ];
    }

    let allFetchedAlerts = [];
    let options = {
      maxItemCount: pageSize,
      continuationToken: continuationToken,
    };
    let hasMoreResults = true;

    while (hasMoreResults && allFetchedAlerts.length < pageSize) {
      let attempts = 0;
      let success = false;
      let fetchedAlerts = [];
      let newContinuationToken = null;

      while (!success && attempts < retryCount) {
        try {
          const response = await alertsContainer.items.query({ query, parameters }, options).fetchNext();

          fetchedAlerts = response.resources;
          newContinuationToken = response.continuationToken;
          success = true;
        } catch (error) {
          attempts++;
          console.error(`Attempt ${attempts} failed:`, error);

          // If maximum attempts reached, rethrow the error
          if (attempts >= retryCount) {
            throw error;
          }

          const delay = retryDelay * Math.pow(2, attempts - 1);
          await new Promise((resolve) => setTimeout(resolve, delay));
        }
      }

      // Check if fetchedAlerts is undefined or empty
      if (!fetchedAlerts || fetchedAlerts.length === 0) {
        hasMoreResults = false;
        break;
      }

      const processedAlerts = fetchedAlerts.map((alert) => processAlertData(alert, deployedModels));

      const filteredAlerts =
        userId === PLIX_OVERWATCH_ACCOUNT
          ? processedAlerts
          : processedAlerts.filter((alert) => alert.Label === 'Escalation' && alert.IsCustomerVisible);

      allFetchedAlerts = [...allFetchedAlerts, ...filteredAlerts];

      if (newContinuationToken) {
        options.continuationToken = newContinuationToken;
      } else {
        hasMoreResults = false;
      }
    }

    const paginatedAlerts = allFetchedAlerts.slice(0, pageSize);
    const hasMore = options.continuationToken != null;

    return {
      alerts: paginatedAlerts,
      hasMore: hasMore,
      continuationToken: options.continuationToken,
    };
  } catch (error) {
    console.error('Error fetching recent escalation alerts:', error);
    return {
      alerts: [],
      hasMore: false,
      continuationToken: null,
      error: 'Failed to fetch recent escalation alerts. Please check your network connection and refresh the page.',
    };
  }
};

export const getAlertLabelingMetrics = async (userId, deviceIds) => {
  try {
    if (deployedModels.length === 0) {
      await loadDeployedModels();
    }

    const alertsContainer = cosmosClient.database('Alerts').container('StateMachineAlerts');
    const fourteenDaysAgo = subDays(new Date(), 14);

    let query;
    let parameters;

    if (userId === PLIX_OVERWATCH_ACCOUNT) {
      query = `
        SELECT * FROM c
        WHERE ARRAY_CONTAINS(@deviceIds, c.DeviceId)
        AND c.Timestamp >= @fourteenDaysAgo
        AND IS_DEFINED(c.VideoFileName)
        AND c.VideoFileName != ''
        AND (NOT IS_DEFINED(c.IsDeleted) OR c.IsDeleted = false)
      `;
      parameters = [
        { name: '@deviceIds', value: deviceIds },
        { name: '@fourteenDaysAgo', value: fourteenDaysAgo.toISOString() },
      ];
    } else {
      query = `
        SELECT * FROM c
        WHERE ARRAY_CONTAINS(@deviceIds, c.DeviceId)
        AND c.userId = @userId
        AND c.Timestamp >= @fourteenDaysAgo
        AND IS_DEFINED(c.VideoFileName)
        AND c.VideoFileName != ''
        AND (NOT IS_DEFINED(c.IsDeleted) OR c.IsDeleted = false)
      `;
      parameters = [
        { name: '@deviceIds', value: deviceIds },
        { name: '@userId', value: userId },
        { name: '@fourteenDaysAgo', value: fourteenDaysAgo.toISOString() },
      ];
    }

    const { resources: fetchedAlerts } = await alertsContainer.items.query({ query, parameters }).fetchAll();

    let totalAlerts = 0;
    let labeledAlerts = 0;
    let correctOrEscalationAlerts = 0;

    fetchedAlerts.forEach((alert) => {
      totalAlerts++;

      const trueLabel = alert.ClassificationLabel || alert.ClassificationResult?.['true'];

      if (trueLabel && trueLabel !== 'Unlabeled') {
        labeledAlerts++;

        if (trueLabel === 'Correct' || trueLabel === 'Escalation') {
          correctOrEscalationAlerts++;
        }
      }
    });

    console.log('labeling fetchedAlerts: ', fetchedAlerts);

    const labelingCoverage = {
      fraction: labeledAlerts / totalAlerts,
      numerator: labeledAlerts,
      denominator: totalAlerts,
    };

    const labelingAccuracy =
      labeledAlerts > 0
        ? {
            fraction: correctOrEscalationAlerts / labeledAlerts,
            numerator: correctOrEscalationAlerts,
            denominator: labeledAlerts,
          }
        : null;

    console.log('labelingCoverage: ', labelingCoverage, ' labelingAccuracy: ', labelingAccuracy);

    return {
      labelingCoverage,
      labelingAccuracy,
    };
  } catch (error) {
    console.error('Error calculating alert labeling metrics:', error);
    console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
    toast.error('Failed to calculate alert labeling metrics. Please try again later.');
    throw error;
  }
};

export const fetchBatteryMetricsForDevices = async (devices) => {
  console.log('Fetching battery metrics for ');
  console.log(devices);
  try {
    const metricsQuery = {
      query: `SELECT * FROM c 
       WHERE ARRAY_CONTAINS(@deviceIds, c.deviceId)`,
      parameters: [{ name: '@deviceIds', value: devices }],
    };
    const { resources: batteryResults } = await batteryMetricsContainer.items.query(metricsQuery).fetchAll();
    console.log(batteryResults);
    return batteryResults;
  } catch (error) {
    console.error(`Error fetching battery metrics:`, error);
    toast.error('Failed to fetch battery metrics. Please try again later.');
    // Return a very old date as default in case of any error
    return new Date(0).toISOString();
  }
};

export const fetchVideosForTimeRange = async (deviceId, startTime, endTime) => {
  try {
    const query = {
      query: `
        SELECT * FROM c 
        WHERE c.deviceId = @deviceId 
        AND c.TimeRecorded >= @startTime 
        AND c.TimeRecorded <= @endTime
        AND IS_DEFINED(c.TranscriptVTTPath)
        ORDER BY c.TimeRecorded ASC
      `,
      parameters: [
        { name: '@deviceId', value: deviceId },
        { name: '@startTime', value: startTime },
        { name: '@endTime', value: endTime },
      ],
    };

    const { resources: videos } = await videosContainer.items.query(query).fetchAll();
    return videos;
  } catch (error) {
    console.error('Error fetching videos for time range:', error);
    toast.error('Failed to fetch videos for the specified time range. Please try again later.');
    throw error;
  }
};

export const fetchLocationEnterEvents = async (deviceId, startTime, endTime) => {
  try {
    const query = {
      query: `
        SELECT * FROM c 
        WHERE c.device = @deviceId 
        AND c.enter_time >= @startTime 
        AND c.enter_time <= @endTime
      `,
      parameters: [
        { name: '@deviceId', value: deviceId },
        { name: '@startTime', value: startTime },
        { name: '@endTime', value: endTime },
      ],
    };
    const { resources: locationEnterEvents } = await locationEventsContainer.items.query(query).fetchAll();
    return locationEnterEvents;
  } catch (error) {
    console.error('Error fetching location enter events:', error);
    toast.error('Failed to fetch location enter events. Please try again later.');
    throw error;
  }
};

export const fetchLatestLocationEvent = async (deviceId, endTime) => {
  try {
    const query = {
      query: `
        SELECT TOP 1 * FROM c 
        WHERE c.device = @deviceId 
        AND c.enter_time <= @endTime
        AND (c.exit_time = null OR c.exit_time >= @endTime)
        ORDER BY c.enter_time DESC
      `,
      parameters: [
        { name: '@deviceId', value: deviceId },
        { name: '@endTime', value: endTime },
      ],
    };
    const { resources: locationEvents } = await locationEventsContainer.items.query(query).fetchAll();
    return locationEvents[0];
  } catch (error) {
    console.error('Error fetching latest location event:', error);
    toast.error('Failed to fetch latest location event. Please try again later.');
    throw error;
  }
};

/*** Not for dashboard, for video management page */
export const fetchVideosForDevices = async (userId, devices, retentionPeriodDays, selectedOrg) => {
  try {
    if (deployedModels.length === 0) {
      await loadDeployedModels();
    }
    // what if the deployed models are not loaded?
    if (deployedModels.length === 0) {
      throw new Error('Deployed models could not be loaded');
    }
    const deviceIds = devices.map((device) => device.deviceId);
    const currentDate = new Date();
    const retentionDate = new Date(
      currentDate.getTime() - (userId === PLIX_OVERWATCH_ACCOUNT ? 365 : retentionPeriodDays) * 24 * 60 * 60 * 1000
    );
    const orgCreationTimestamp = await fetchOrgCreationTimestamp(
      userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId
    );

    const query = {
      query: `
        SELECT * from c 
        WHERE ARRAY_CONTAINS(@deviceIds, c.deviceId)
        AND c.TimeRecorded >= @orgCreationTimestamp
        AND (NOT IS_DEFINED(c.isArchived) OR c.isArchived = false)
        AND (NOT IS_DEFINED(c.displayStatus) OR c.displayStatus = true)
        AND IS_DEFINED(c.TranscriptVTTPath)
        AND c.TimeRecorded >= @retentionDate
        ORDER BY c.TimeRecorded DESC
      `,
      parameters: [
        { name: '@deviceIds', value: deviceIds },
        { name: '@retentionDate', value: retentionDate.toISOString() },
        { name: '@userId', value: userId === PLIX_OVERWATCH_ACCOUNT ? selectedOrg : userId },
        { name: '@orgCreationTimestamp', value: orgCreationTimestamp },
      ],
    };

    const { resources: items } = await videosContainer.items.query(query).fetchAll();
    console.log('items: ', items);
    const filteredVideos = items.filter((video) => {
      // Keep videos that don't have ClassificationResult field for backwards compat
      if (!video.ClassificationResult) {
        return true;
      }

      // For videos with ClassificationResult, check the first deployed model
      for (const model of deployedModels) {
        // if the model is the human label, we override the ai cla
        if (video.ClassificationResult[model] === 'Unlabeled') {
          continue;
        }
        if (model in video.ClassificationResult) {
          // Keep the video if the first found model classifies it as Escalation
          return video.ClassificationResult[model] === 'Escalation';
        }
      }

      // If no deployed model is found in ClassificationResult, keep the video
      return true;
    });

    return filteredVideos;
  } catch (error) {
    console.error('Error fetching videos from Cosmos DB:', error);
    throw error;
  }
};

export const fetchVideoByFileName = async (fileName) => {
  try {
    const querySpec = {
      query: 'SELECT * FROM c WHERE c.FileName = @fileName',
      parameters: [{ name: '@fileName', value: fileName }],
    };

    const { resources } = await videosContainer.items.query(querySpec).fetchAll();

    if (resources.length > 0) {
      return resources[0];
    } else {
      console.warn(`No video found with filename: ${fileName}`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching video by filename:', error);
    return null;
  }
};
