import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, Circle, useMapEvents, useMap } from 'react-leaflet';
import { getDistance } from 'geolib';
import { format, formatDistanceToNowStrict, isThisYear } from 'date-fns';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { HLSVideoPlayer, getBatteryIcon, convertCelsiusToFahrenheit } from './LivestreamComponents';
import AlertPopup from './AlertPopup';
import { PLIX_OVERWATCH_ACCOUNT } from '../utils/utilsEvents';
import { Modal } from './LiveStreamModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faThermometerHalf,
  faBatteryFull,
  faBatteryThreeQuarters,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryEmpty,
  faChargingStation,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';

const ZoomDependentSiteMarker = ({ position, icon: initialIcon }) => {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());

  useEffect(() => {
    const handleZoomEnd = () => {
      setZoomLevel(map.getZoom());
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
    };
  }, [map]);

  const getMarkerSize = () => {
    if (zoomLevel >= 14) return 60;
    if (zoomLevel >= 10) return 40;
    return 20;
  };

  const getMarkerOpacity = () => {
    if (zoomLevel >= 14) return 0.2;
    if (zoomLevel >= 10) return 0.1;
    return 0.05;
  };

  const customIcon = L.divIcon({
    className: 'custom-site-icon',
    html: `<svg width="${getMarkerSize()}" height="${getMarkerSize()}" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <circle cx="30" cy="30" r="25" fill="red" opacity="${getMarkerOpacity()}"/>
    </svg>`,
    iconSize: [getMarkerSize(), getMarkerSize()],
    iconAnchor: [getMarkerSize() / 2, getMarkerSize() / 2],
    popupAnchor: [0, -getMarkerSize() / 2],
  });

  return <Marker position={position} icon={customIcon} />;
};

const DynamicGeofenceCircle = ({ geofence, isSelected }) => {
  const map = useMap();
  const [zoomLevel, setZoomLevel] = useState(map.getZoom());

  useEffect(() => {
    const handleZoomEnd = () => {
      setZoomLevel(map.getZoom());
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
    };
  }, [map]);

  const getOpacity = () => {
    if (zoomLevel >= 14) return 0.2;
    if (zoomLevel >= 10) return 0.2;
    return 0;
  };

  const getWeight = () => {
    return isSelected ? 1 : 0;
  };

  return (
    <Circle
      center={[geofence.latitude, geofence.longitude]}
      radius={geofence.radius}
      pathOptions={{
        color: 'red',
        fillColor: 'red',
        fillOpacity: getOpacity(),
        weight: getWeight(),
      }}
    />
  );
};

function MapViewMain({
  memoizedDevices,
  deviceShifts,
  handleToggleStream,
  streamingDevices,
  mapRef,
  markerRefs,
  alertMarkerRef,
  activeDevice,
  activeAlert,
  alertMarker,
  handleOpenPopup,
  handleClosePopup,
  handleCloseAlertPopup,
  geofences,
  userId,
  previewGeofence,
  onPreviewGeofenceRadiusChange,
  selectedGeofence,
}) {
  // Remove local state and refs that are now lifted up
  // const markerRefs = useRef({});
  // const alertMarkerRef = useRef(null);
  // const [activeDevice, setActiveDevice] = useState(null);
  // const [activeAlert, setActiveAlert] = useState(null);
  // const [alertMarker, setAlertMarker] = useState(null);

  // Use the props instead
  console.log('selectedGeofence', selectedGeofence);

  const [modalDevice, setModalDevice] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const createCustomSiteIcon = useCallback(() => {
    // This function is no longer needed as we're using ZoomDependentSiteMarker
    return null;
  }, []);

  const createCustomIcon = (status, isActive = false, isRecording = false) => {
    let statusClass;
    // console.log('isRecording', isRecording.isRecording);
    if (isRecording.isRecording && status === 'In Shift') {
      status = 'Recording';
    }
    switch (status) {
      case 'Recording':
        statusClass = 'recording';
        break;
      case 'In Shift':
        statusClass = 'in-shift';
        break;
      case 'Offline':
        statusClass = 'offline';
        break;
      case 'Unknown':
        statusClass = 'default';
        break;
      default:
        statusClass = 'default';
    }

    return L.divIcon({
      className: `circle-icon ${statusClass}`,
      html: `<img src="/plix_logo_black.svg" alt="Device Logo"/>`,
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: [0, -16],
    });
  };

  const formatLastLocatedTime = (date) => {
    if (!date) return 'Unknown';
    const now = new Date();
    const distance = formatDistanceToNowStrict(date, { addSuffix: true });

    // Replace words with numbers and remove spaces between numbers and units
    const formattedDistance = distance
      .replace(/^about /, '')
      .replace(/^less than /, '')
      .replace(/^an? /, '1')
      .replace(/(\d+|\d+\.\d+)\s+(second|minute|hour|day|month|year)s?/i, (match, p1, p2) => {
        const unit = p2.toLowerCase().charAt(0);
        return `${Math.round(parseFloat(p1))}${unit}`;
      });

    if (formattedDistance.includes('month') || formattedDistance.includes('year')) {
      return formattedDistance;
    } else {
      return formattedDistance
        .replace('minutes', 'm')
        .replace('minute', 'm')
        .replace('hours', 'h')
        .replace('hour', 'h')
        .replace('days', 'd')
        .replace('day', 'd')
        .replace('seconds', 's')
        .replace('second', 's');
    }
  };

  const formatTooltipTime = (date) => {
    if (!date) return 'Unknown';
    const now = new Date();
    const timeFormat = 'h:mm a';

    if (isThisYear(date)) {
      if (date.toDateString() === now.toDateString()) {
        return `Last located today at ${format(date, timeFormat)}`;
      } else if (date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString()) {
        return `Last located yesterday at ${format(date, timeFormat)}`;
      } else {
        return `Last located ${format(date, 'M/d')} at ${format(date, timeFormat)}`;
      }
    } else {
      return `Last located ${format(date, 'M/d/yyyy')} at ${format(date, timeFormat)}`;
    }
  };

  const fitMapToGeofence = useCallback((latitude, longitude, radius) => {
    if (mapRef.current) {
      const map = mapRef.current;
      const center = [latitude, longitude];
      const zoom = map.getBoundsZoom(
        L.latLngBounds([
          [latitude - radius / 111320, longitude - radius / (111320 * Math.cos((latitude * Math.PI) / 180))],
          [latitude + radius / 111320, longitude + radius / (111320 * Math.cos((latitude * Math.PI) / 180))],
        ]),
        false,
        [50, 50] // Padding to ensure the circle is fully visible
      );
      map.flyTo(center, zoom, { animate: true, duration: 1.5 });
    }
  }, []);

  useEffect(() => {
    if (selectedGeofence) {
      fitMapToGeofence(selectedGeofence.latitude, selectedGeofence.longitude, selectedGeofence.radius);
    }
  }, [selectedGeofence, fitMapToGeofence]);

  return (
    <>
      <MapContainer
        center={memoizedDevices.length > 0 ? memoizedDevices[0].location : [40.075044252652596, -98.80344386500497]}
        zoom={memoizedDevices.length > 0 ? 18 : 0}
        style={{ height: '100vh', width: '100%' }}
        zoomControl={false}
        whenCreated={(map) => {
          mapRef.current = map;
          // Any initial setup here
        }}
        ref={mapRef}
      >
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`}
          attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
          tileSize={512}
          zoomOffset={-1}
        />
        {geofences.map((geofence) => (
          <React.Fragment key={geofence.id}>
            <ZoomDependentSiteMarker position={[geofence.latitude, geofence.longitude]} />
            <DynamicGeofenceCircle
              geofence={geofence}
              isSelected={selectedGeofence && selectedGeofence.id === geofence.id}
            />
          </React.Fragment>
        ))}
        {previewGeofence && (
          <React.Fragment>
            <ZoomDependentSiteMarker position={[previewGeofence.latitude, previewGeofence.longitude]} />
            <DynamicGeofenceCircle geofence={previewGeofence} isSelected={true} />
          </React.Fragment>
        )}

        {memoizedDevices.map((device, index) => (
          <Marker
            key={index}
            position={device.location}
            icon={createCustomIcon(device.deviceState, activeDevice === device.deviceId, deviceShifts[device.deviceId])}
            ref={(el) => {
              if (el) {
                markerRefs.current[device.deviceId] = el;
              }
            }}
            eventHandlers={{
              popupopen: (e) => {
                handleOpenPopup(device.deviceId);
                const closeButton = e.popup._closeButton;
                if (closeButton) {
                  closeButton.style.width = '30px';
                  closeButton.style.height = '30px';
                  closeButton.style.fontSize = '24px';
                  closeButton.style.lineHeight = '30px';
                }
              },
              popupclose: () => handleClosePopup(device.deviceId),
            }}
          >
            <Popup className="video-popup">
              <div className="video-popup-inner">
                <div className="live-popup-header">
                  <div className="device-info">
                    <Tooltip title="Owner of device" placement="top">
                      <span className="assigned-to">{device.assignedTo}</span>
                    </Tooltip>
                    <Tooltip title="Device ID shown on device" placement="top">
                      <span className="device-id">{device.deviceId}</span>
                    </Tooltip>
                    <Tooltip title="Battery level" placement="top">
                      <span className="device-status">
                        {getBatteryIcon(device.batteryStatus, device.isCharging)}
                        {device.batteryStatus}%
                      </span>
                    </Tooltip>
                    {formatLastLocatedTime(device.lastLocatedTime) !== 'Unknown' && (
                      <Tooltip title={formatTooltipTime(device.lastLocatedTime)} placement="top">
                        <span className="last-located-time">
                          <span className="pulsating-icon-wrapper">
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="pulsating-icon" />
                          </span>
                          <span className="time-text">{formatLastLocatedTime(device.lastLocatedTime)}</span>
                        </span>
                      </Tooltip>
                    )}
                    {/* {device.temperature !== null && device.temperature !== undefined && (
                      <Tooltip title="Device Temperature" placement="top">
                        <span className="device-temperature">
                          <FontAwesomeIcon icon={faThermometerHalf} />
                          {convertCelsiusToFahrenheit(device.temperature)}°F
                        </span>
                      </Tooltip>
                    )} */}
                  </div>
                </div>
                {device.config.streamAddress.endsWith('.m3u8') &&
                device.deviceState !== 'Offline' &&
                device.deviceState !== 'Unknown' &&
                (device.liveViewPolicy === 'Only During Recording'
                  ? device.deviceState === 'Recording'
                  : device.liveViewPolicy === undefined || device.liveViewPolicy === 'During Shift') ? (
                  <HLSVideoPlayer
                    hlsUrl={device.config.streamAddress}
                    deviceId={device.deviceId}
                    isStreaming={streamingDevices[device.deviceId] || false}
                    onToggleStream={handleToggleStream}
                    onError={(error) => console.error('HLS Player Error:', error)}
                  />
                ) : device.deviceState === 'Offline' ? (
                  <div className="offline-message">
                    <p>Device is offline</p>
                  </div>
                ) : device.liveViewPolicy === 'Only During Recording' && device.deviceState !== 'Recording' ? (
                  <div className="offline-message">
                    <p>Live view can only be started while recording.</p>
                  </div>
                ) : (
                  <div className="offline-message">
                    <p>Live stream not available</p>
                  </div>
                )}
              </div>
            </Popup>
          </Marker>
        ))}
        {alertMarker && activeAlert && (
          <Marker
            position={alertMarker}
            icon={L.divIcon({
              className: 'alert-marker',
              html: '<div style="background-color: red; width: 20px; height: 20px; border-radius: 50%; opacity: 0.7;"></div>',
              iconSize: [20, 20],
              iconAnchor: [10, 10],
            })}
            ref={alertMarkerRef}
            eventHandlers={{
              popupopen: (e) => {
                const closeButton = e.popup._closeButton;
                if (closeButton) {
                  closeButton.style.width = '30px';
                  closeButton.style.height = '30px';
                  closeButton.style.fontSize = '24px';
                  closeButton.style.lineHeight = '30px';
                }
              },
              popupclose: handleCloseAlertPopup,
            }}
          >
            <Popup className="video-popup" onClose={handleCloseAlertPopup}>
              {activeAlert && <AlertPopup alert={activeAlert} userId={userId} />}
            </Popup>
          </Marker>
        )}

        <ZoomControl position="bottomright" />
      </MapContainer>
      {modalDevice && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)} modalDevice={modalDevice}>
          {modalDevice.config.streamAddress.endsWith('.m3u8') ? (
            <HLSVideoPlayer src={modalDevice.config.streamAddress} style={{ width: '100%', height: 'auto' }} />
          ) : (
            <img
              src={modalDevice.config.streamAddress}
              alt="Full Size Stream"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default React.memo(MapViewMain);
