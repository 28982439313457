export function getCoordinatesFromHash(locationHash) {
  // Remove the first 18 characters (SRID info)
  const coordsHex = locationHash.slice(18);
  // Split the remaining hex string into two 16-character parts
  const lonHex = coordsHex.slice(0, 16);
  const latHex = coordsHex.slice(16);
  // Convert hex to float64
  const longitude = hexToFloat64(lonHex);
  const latitude = hexToFloat64(latHex);
  return { longitude, latitude };
}

function hexToFloat64(hex) {
  // Reverse byte order
  const reversedHex = hex.match(/../g).reverse().join('');
  // Convert to binary
  const binary = parseInt(reversedHex, 16).toString(2).padStart(64, '0');
  // Extract sign, exponent, and fraction
  const sign = binary[0] === '1' ? -1 : 1;
  const exponent = parseInt(binary.slice(1, 12), 2) - 1023;
  const fraction = parseInt(binary.slice(12), 2) / Math.pow(2, 52) + 1;
  // Calculate the final number
  return sign * fraction * Math.pow(2, exponent);
}
