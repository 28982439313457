// LabelAlertPopup.js
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import '../styles/LabelAlertPopup.css';

import categoriesConfig from '../config/categories.json';

const categories = categoriesConfig.categories.map((category) => ({
  label: category.name,
  description: category.description,
}));

const LabelAlertPopup = ({ open, onClose, onSubmit, initialValues }) => {
  const [classificationLabel, setClassificationLabel] = useState(initialValues.ClassificationLabel || 'Unlabeled');
  const [categorizationLabels, setCategorizationLabels] = useState([]);
  const [isDeleted, setIsDeleted] = useState(initialValues.IsDeleted || false);

  useEffect(() => {
    setClassificationLabel(initialValues.ClassificationLabel || 'Unlabeled');
    setCategorizationLabels(
      initialValues.CategorizationLabel ? initialValues.CategorizationLabel.split(',').map((label) => label.trim()) : []
    );
    setIsDeleted(initialValues.IsDeleted || false);
  }, [initialValues]);

  const handleClassificationChange = (_, newValue) => {
    if (newValue !== null) {
      setClassificationLabel(newValue);
    }
  };

  const handleVisibilityChange = (_, newValue) => {
    if (newValue !== null) {
      setIsDeleted(newValue === 'Delete');
    }
  };

  const handleSubmit = () => {
    onSubmit({
      ClassificationLabel: classificationLabel,
      CategorizationLabel: categorizationLabels
        .map((label) => (typeof label === 'string' ? label : label.label))
        .join(','),
      IsDeleted: isDeleted,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="label-alert-modal-title"
      aria-describedby="label-alert-modal-description"
    >
      <div className="label-alert-modal-container">
        <h2 id="label-alert-modal-title">Label Escalation</h2>
        <div className="label-alert-content">
          <div className="label-alert-section">
            <label>Classification Label</label>
            <ToggleButtonGroup
              value={classificationLabel}
              exclusive
              onChange={handleClassificationChange}
              className="toggle-button-group"
            >
              <ToggleButton value="Unlabeled">Unlabeled</ToggleButton>
              <ToggleButton value="Correct">Correct</ToggleButton>
              <ToggleButton value="Error">Error</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="label-alert-section">
            <label>Escalation Categories</label>
            <Autocomplete
              multiple
              freeSolo
              options={categories}
              value={categorizationLabels}
              onChange={(_, newValue) => setCategorizationLabels(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={typeof option === 'string' ? option : option.label}
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              renderInput={(params) => <TextField {...params} />}
              renderOption={(props, option) => (
                <li {...props}>
                  <div>
                    {option.label}
                    <br />
                    <span className="category-description">{option.description}</span>
                  </div>
                </li>
              )}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
              isOptionEqualToValue={(option, value) => option.label === value || option.label === value.label}
              className="autocomplete"
            />
          </div>
          <div className="label-alert-section">
            <label>Customer Visibility</label>
            <ToggleButtonGroup
              value={isDeleted ? 'Delete' : 'Show'}
              exclusive
              onChange={handleVisibilityChange}
              className="toggle-button-group"
            >
              <ToggleButton value="Show">Show</ToggleButton>
              <ToggleButton value="Delete">Delete</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="label-alert-buttons">
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
          <button onClick={handleSubmit} className="submit-button">
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LabelAlertPopup;
